import './index.css';
import React from 'react';
import ReactDOM from 'react-dom'; 
import {Provider} from 'react-redux';
import App from './App';
import store from './store';

/*
const store = createStore(allReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
*/

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <App />
    </Provider>,
  </React.StrictMode>,
  document.getElementById('root')
);
