import { DEFAULT_ORDERING, DEFAULT_ORIGIN_ID, PARAMETER_PAGE_LIMIT, SET_ACCESS_METHOD, SET_CATEGORY, SET_DISCIPLINE, SET_FORMAT, SET_LOADING, SET_N_ENTRIES, SET_ORIGIN, SET_ORDERING, SET_PAGE_NUMBER, SET_SEARCH, SET_TABLE, REGEX} from "../constDefinition";

const initialState = {
    pageNentries: PARAMETER_PAGE_LIMIT,
    pageNumber: 0,
    loading: false,
    search: "",
    format: "",
    table: "",
    category: "",
    discipline: "",
    accessMethod: "",
    origin: DEFAULT_ORIGIN_ID,
    ordering: DEFAULT_ORDERING,
    regex: false
}

const filtersReducer = (state = initialState, action) => {
    switch(action.type)
    {
        case SET_N_ENTRIES:
            return { 
                ...state, 
                pageNentries: action.value 
            }
        case SET_PAGE_NUMBER:
            return { 
                ...state, 
                pageNumber: action.value 
            }
        case SET_LOADING:
            return { 
                ...state, 
                loading: action.value 
            }
        case SET_SEARCH:
            return { 
                ...state, 
                search: action.value 
            }
        case SET_ACCESS_METHOD:
            return { 
                ...state, 
                accessMethod: action.value 
            }
        case SET_FORMAT:
            return { 
                ...state, 
                format: action.value 
            }
        case SET_TABLE:
            return { 
                ...state, 
                table: action.value 
            }
        case SET_CATEGORY:
            return { 
                ...state, 
                category: action.value 
            }
        case SET_DISCIPLINE:
            return { 
                ...state, 
                discipline: action.value 
            }
        case SET_ORIGIN:
            return { 
                ...state, 
                origin: action.value 
            }
        case SET_ORDERING:
            return {
                ...state,
                ordering: action.value
            }
        case REGEX:
            return{
                ...state,
                regex: action.value
            }
      default: return state;
    }
  };
  
  export default filtersReducer;