/* AUTHENTICATION */
export const LOGGED = "LOGGED";
export const NOT_LOGGED = "NOT_LOGGED";
export const SET_ADMIN = "SET_ADMIN";

/* DEFAULTS */

export const PARAMETER_PAGE_LIMIT = "20"
export const DEFAULT_ORIGIN_NAME = "European Center for Medium-Range Weather Forecasts"
export const DEFAULT_ORIGIN_ID = 98

export const DEFAULT_ORDERING = "id"

/* PARAMETERS OVERVIEW */
export const SET_PARAMETERS = 'SET_PARAMETERS';

/* FILTERS */
export const SET_N_ENTRIES = 'SET_N_ENTRIES';
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
export const SET_LOADING = 'SET_LOADING';
export const SET_PAGE = 'SET_PAGE';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_FORMAT = 'SET_FORMAT';
export const SET_ACCESS_METHOD = 'SET_ACCESS_METHOD';
export const SET_TABLE = 'SET_TABLE';
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_DISCIPLINE = 'SET_DISCIPLINE';
export const SET_ORIGIN = 'SET_ORIGIN';
export const SET_ORDERING = 'SET_ORDERING';

/* DEFINITION */
export const ACCESS = "ACCESS";
export const UNITS = "UNITS";
export const TABLES = "TABLES";
export const ENCODING = "ENCODING";
export const ORIGINS = "ORIGINS";
export const DISCIPLINES = "DISCIPLINES";
export const CATEGORIES = "CATEGORIES";


/* PARAMETER DETAIL */
export const SET_CLONE = "SET_CLONE";
export const SET_PARAMETER_SELECTED = "SET_PARAMETER_SELECTED";

export const SET_PARAMETER_SELECTED_GRIB1_DEFAULT_ENCODINGS = "SET_PARAMETER_SELECTED_GRIB1_DEFAULT_ENCODINGS";
export const SET_PARAMETER_SELECTED_GRIB1_OTHER_ENCODINGS = "SET_PARAMETER_SELECTED_GRIB1_OTHER_ENCODINGS";

export const SET_PARAMETER_SELECTED_GRIB2_DEFAULT_ENCODINGS = "SET_PARAMETER_SELECTED_GRIB2_DEFAULT_ENCODINGS";
export const SET_PARAMETER_SELECTED_GRIB2_OTHER_ENCODINGS = "SET_PARAMETER_SELECTED_GRIB2_OTHER_ENCODINGS";

export const SET_PARAMETER_SELECTED_NETCDF_ENCODINGS = "SET_PARAMETER_SELECTED_NETCDF_ENCODINGS";

/* ORDERING */
export const ORDERED_BY_ID_ASC = "id";
export const ORDERED_BY_ID_DESC = "-id";
export const ORDERED_BY_NAME_ASC = "name";
export const ORDERED_BY_NAME_DESC = "-name";
export const ORDERED_BY_SHORT_NAME_ASC = "shortname";
export const ORDERED_BY_SHORT_NAME_DESC = "-shortname";
export const ORDERED_BY_UNIT_NAME_ASC = "units__name";
export const ORDERED_BY_UNIT_NAME_DESC = "-units__name";

/* REGE */
export const REGEX = "REGEX";

export const SWITCH_OFF = false
export const SWITCH_ON = true

