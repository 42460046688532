import { ACCESS, CATEGORIES, DISCIPLINES, ENCODING, ORIGINS, TABLES, UNITS } from "../constDefinition";

const initialState = {
    access: {},
    accessByLabel: {},
    units: {},
    unitsByLabel: {},
    tables: {},
    tablesByLabel: {},
    encoding: {},
    encodingByLabel: {},
    origins: {},
    originsByLabel: {},
    disciplines: {},
    disciplinesByLabel: {},
    categories: {},
    categoriesByLabel: {}
}

const definitionReducer = (state = initialState, action) => {
    switch(action.type)
    {
        case ACCESS:
            return { 
                ...state, 
                access: Object.assign({}, ...action.values.map((x) => ({[x.id]: x}))), 
                accessByLabel: Object.assign({}, ...action.values.map((x) => ({[x.label]: x}))),
            }
        case UNITS:
            return { 
                ...state,
                units: Object.assign({}, ...action.values.map((x) => ({[x.id]: x}))), 
                unitsByLabel: Object.assign({}, ...action.values.map((x) => ({[x.name]: x}))), 
            }
        case TABLES:
            return { 
                ...state, 
                tables: Object.assign({}, ...action.values.map((x) => ({[x.id]: x}))), 
                tablesByLabel: Object.assign({}, ...action.values.map((x) => ({[x.name]: x}))), 
            }
        case ENCODING:
            return { 
                ...state, 
                encoding: Object.assign({}, ...action.values.map((x) => ({[x.id]: x}))), 
                encodingByLabel: Object.assign({}, ...action.values.map((x) => ({[x.label]: x}))), 
            }
        case ORIGINS:
            return { 
                ...state,
                origins: Object.assign({}, ...action.values.map((x) => ({[x.id]: x}))), 
                originsByLabel: Object.assign({}, ...action.values.map((x) => ({[x.name]: x}))), 
            }
        case DISCIPLINES:
            return { 
                ...state, 
                disciplines: Object.assign({}, ...action.values.map((x) => ({[x.id]: x}))), 
                disciplinesByLabel: Object.assign({}, ...action.values.map((x) => ({[x.name]: x}))), 
            }
        case CATEGORIES:
            return { 
                ...state, 
                categories: Object.assign({}, ...action.values.map((x) => ({[x.id]: x}))), 
                categoriesByLabel: Object.assign({}, ...action.values.map((x) => ({[x.name]: x}))), 
            }
      default: return state;
    }
  };
  
  export default definitionReducer;