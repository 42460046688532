import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import { setClone, setLoading, setNEntriesPagination, setPageNumber, setParameters, setRegex, setSearch } from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import { getParameters } from '../services/ParameterService';
import { getFilterParams } from '../composeParameters';
import { useRef } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate, useSearchParams } from "react-router-dom";
import Switch from '@mui/material/Switch';
import { SWITCH_OFF, SWITCH_ON } from '../constDefinition';


const HeaderTablePage = () => {

  // Add+/clone status
  
  // const [form, setForm] = useState();
  // const addButton = () => setForm(1);

  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams();
  var isLogged = useSelector(state => state.auth.logged)
  var isAdmin = useSelector(state => state.auth.admin)
  const [searchValue, setSearchValue] = useState(searchParams.get("search"));
  const [regexSearchStatus, setRegexSearchStatus] = useState(searchParams.get("regex") ? true: false);

  const searchFieldRef = useRef('')
  const navigate = useNavigate();

  useEffect(() => {
    if(searchValue){
      dispatch(setSearch(searchValue))
    }
    dispatch(setRegex(regexSearchStatus))
  }, [])

  const pageNentries = useSelector(state => state.filters.pageNentries);
  const page = useSelector(state => state.filters.pageNumber);

  const nEntriesValues = ["10", "20", "50", "100", "All"]
  var params = getFilterParams()
  if(pageNentries != "All"){
    params['limit'] = pageNentries
    params['offset'] = page*pageNentries
  }
  else{
    delete params['limit']
    delete params['offset']
  }

  const handleNEntriesChange = (nEntriesSelected) => {
    if(nEntriesSelected != "All"){
      params['limit'] = nEntriesSelected
      params['offset'] = 0
    }
    else{
      delete params['limit']
      delete params['offset']
    }

    dispatch(setPageNumber(0))
    dispatch(setNEntriesPagination(nEntriesSelected))
    dispatch(setLoading(true))
    getParameters(params)
    .then(response => {
      //isAdmin ? dispatch(setParameters(response.data)) : dispatch(setParameters(response.data.filter(par => par.published))) 
      dispatch(setParameters(response.data))
      dispatch(setLoading(false))
    })
  }

  const changeSwitchStatus = (event) => {
    if(regexSearchStatus===false){
      setRegexSearchStatus(SWITCH_ON)
      console.log('switch ON')
      dispatch(setRegex(true))  
      params['regex'] = true
    } 
    else {
      setRegexSearchStatus(SWITCH_OFF)
      console.log('switch OFF')
      dispatch(setRegex(false))
      delete params['regex']
    }

    getParameters(params)
    .then(response => {
      //isAdmin ? dispatch(setParameters(response.data)) : dispatch(setParameters(response.data.filter(par => par.published)))
      dispatch(setParameters(response.data))
      dispatch(setLoading(false))
    })
  }

  const search = () => {
    let value = searchFieldRef.current.value
    if(value && value != ""){
      dispatch(setSearch(value))
      setSearchValue(value)
      params['search'] = value
      searchParams.has('search') ? searchParams.set('search', value): searchParams.append('search', value)
      setSearchParams(searchParams);
        if(regexSearchStatus===true){
          console.log('regular expressions ON')
          dispatch(setSearch(value))
          setSearchValue(value)
          params['search'] = value
          params['regex'] = true
          searchParams.has('search') ? searchParams.set('search', value): searchParams.append('search', value)
          searchParams.has('regex') ? searchParams.set('regex', true) : searchParams.append('regex', true)
          setSearchParams(searchParams)
        }
        else if(regexSearchStatus===false){
          console.log('regular expressions OFF')
          dispatch(setSearch(value))
          setSearch(value)
          params['search'] = value
          searchParams.has('search') ? searchParams.set('search', value): searchParams.append('search', value)
          searchParams.has('regex') ? searchParams.delete('regex', true) : searchParams.delete('regex', true)
          setSearchParams(searchParams)
        }
    }
    else {
        dispatch(setSearch(''))
        setSearchValue('')
        delete params['search']
        searchParams.delete('search')
        searchParams.has('regex') ? searchParams.delete('regex', SWITCH_OFF) : searchParams.delete('regex', SWITCH_OFF)
        setSearchParams(searchParams);
    }
    
    dispatch(setLoading(true))
    getParameters(params)
    .then(response => {
      //isAdmin ? dispatch(setParameters(response.data)) : dispatch(setParameters(response.data.filter(par => par.published)))
      dispatch(setParameters(response.data))
      dispatch(setLoading(false))
    })
  }
  
  const handleClearSearch = () =>{
    searchFieldRef.current.value = ""
    search()
  }

  const EnterKey = (ev) => {
    if (ev.key === 'Enter') {
      search()
      ev.preventDefault();
    }
  }

  const handleChange = (ev) => {
    if (ev.target.value === "") {
      handleClearSearch()
    }
  }

  const getStackSearch = () => {
    return (
      <Grid container>
        <Stack direction="row" alignItems="center">
          <Grid container direction={'column'}>
            <Grid item sx={12}>
              <TextField
                hiddenLabel
                id="filled-hidden-label-normal"
                placeholder="Find parameters"
                variant="outlined"
                inputRef={searchFieldRef}
                defaultValue={searchValue}
                sx={{ width: 220, height: 38 }}
                size="small"
                style={{marginRight: "10px"}}
                onKeyDown={(EnterKey)}
                onChange={(handleChange)}
                InputProps={{ 
                  endAdornment: 
                    <IconButton style={{ width: "35px", height: "35px"}} onClick={handleClearSearch}>
                      <ClearIcon/>
                    </IconButton> ,
                  startAdornment:
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                }}
              />
            </Grid>
            <Grid item sx={12} style={{textAlign: 'end'}} class="regex-switch">
              <span>Use RegEx</span>
              <Switch 
                onChange={changeSwitchStatus}
                checked={regexSearchStatus}
                inputProps={{ 'aria-label': 'controlled' }}
              >
              </Switch>
            </Grid>

          </Grid>

          <Grid container direction={'row'} style={{display: 'flex', justifyContent: "center", alignItems: "center"}}>
            <Grid item sx={12}>
              <Button 
                sx={{textTransform: "capitalize", width: 85, height: 38, marginLeft: "10px", marginBottom: "35px"}} 
                variant="outlined"
                onClick={search}>
                Search
              </Button>
            </Grid>

            <Grid item sx={12}>
              {isLogged && isAdmin &&
                <Button
                  className="buttonPrimary"
                  sx={{textTransform: "capitalize", width: 85, height: 38, marginLeft: "10px", marginBottom: "35px"}} 
                  variant="contained"
                  onClick={createParameter}>
                  + Add
                </Button>
              }
            </Grid>
          </Grid>
        </Stack>
      </Grid>
    );
  }

  const createParameter = () => {
    dispatch(setClone(false))
    navigate("/parameter-create");
  }


  const getStackShowEntries = () => {
    return (
      <Stack direction="row" spacing={1} alignItems="center" className={"container-center"}>
        <div class="child-center">Show</div>

        <Autocomplete
          disablePortal
          id="n-entries-box-demo"
          options={nEntriesValues}
          value={pageNentries}
          // sx={{ width: 100, height: 55 }}
          onChange={(event, nEntriesSelected) => handleNEntriesChange(nEntriesSelected)}
          renderInput={(params) => <TextField {...params} size="small" />}
        />

        <div class="child-center">entries</div>
      </Stack>
    );
  }

  return (
      <div className="header-table-page">
        <Grid container spacing={0}>
          <Grid item md={2} p={4}>
          </Grid>
          <Grid item md={10} px={4} pt={4} pb={2}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h1>Parameter database</h1>
              </Grid>
            </Grid>
            <Grid   container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={3}>

                <Grid item>
                  {getStackShowEntries()}
                </Grid>
                <Grid item>
                  {getStackSearch()}
                </Grid>  
              </Grid>
          </Grid>
        </Grid>
      </div>
  );
};

export default HeaderTablePage;