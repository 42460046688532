import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import ParametersTable from '../components/ParametersTable';


const Home = () => {

  return (
    <ParametersTable/>
  );
};

export default Home;