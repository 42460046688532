import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { setAccess, setAccessMethod, setCategory, setDiscipline, setFormat, setLoading, setNEntriesPagination, setOrigin, setOrigins, setPageNumber, setParameters, setTable } from '../actions';
import { getParameters } from '../services/ParameterService';
import { getFilterParams } from '../composeParameters';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { DEFAULT_ORIGIN_ID, DEFAULT_ORIGIN_NAME } from '../constDefinition';
import { useSearchParams } from "react-router-dom";
import _ from "underscore";
import { dictIsEmpty } from '../shared/Utils';
import { Check, CheckCircle, DoNotDisturb, Pending } from '@mui/icons-material';


const FiltersTable = ({}) => {  

  const dispatch = useDispatch();
  var isLogged = useSelector(state => state.auth.logged)
  var isAdmin = useSelector(state => state.auth.admin)

  //DEFINITION
  const formatsFiltersOptions = useSelector(state => state.definition.encoding,  _.isEqual)
  const formatsFiltersOptionsByLabel = useSelector(state => state.definition.encodingByLabel,  _.isEqual)

  const accessMethodOptions = useSelector(state => state.definition.access, _.isEqual)
  const accessMethodOptionsByLabel = useSelector(state => state.definition.accessByLabel, _.isEqual)

  const tableGrib1Options = useSelector(state => state.definition.tables, _.isEqual)
  const tableGrib1OptionsByLabel = useSelector(state => state.definition.tablesByLabel, _.isEqual)

  const disciplineGrib2Options = useSelector(state => state.definition.disciplines, _.isEqual)
  const disciplineGrib2OptionsByLabel = useSelector(state => state.definition.disciplinesByLabel, _.isEqual)

  const categoriesGrib2Options = useSelector(state => state.definition.categories, _.isEqual)
  const categoriesGrib2OptionsByLabel = useSelector(state => state.definition.categoriesByLabel, _.isEqual)

  const originFiltersOptions = useSelector(state => state.definition.origins, _.isEqual)
  const originFiltersOptionsByLabel = useSelector(state => state.definition.originsByLabel, _.isEqual)

  //FILTER
  const [searchParams, setSearchParams] = useSearchParams();

  const accessMethod = searchParams.get("access")  
  const [accessMethodSelected, setAccessMethodSelected] = useState("");

  const origin = searchParams.get("origin")
  const [originSelected, setOriginSelected] = useState("");

  const format = searchParams.get("encoding")
  const [formatSelected, setFormatSelected] = useState("");

  const grib1Table = searchParams.get("table")
  const [grib1TableSelected, setGrib1TableSelected] = useState("");

  const grib2Discipline = searchParams.get("discipline")
  const [grib2DisciplineSelected, setGrib2DisciplineSelected] = useState("");

  const grib2Category = searchParams.get("category")
  const [grib2CategorySelected, setGrib2CategorySelected] = useState("");

  const [grib1Selected, setGrib1Selected] = useState(formatSelected == "GRIB1");
  const [grib2Selected, setGrib2Selected] = useState(formatSelected == "GRIB2");
  const [netCDFSelected, setNetCDFSelected] = useState(formatSelected == "NetCDF");

  useEffect(() => {
    const originSelectedCalculated = (dictIsEmpty(originFiltersOptions) || !origin)  ? DEFAULT_ORIGIN_NAME: origin == 'all' ? "All": accessMethodOptions[accessMethod].label
    setOriginSelected(originSelectedCalculated)
    if(origin){
      dispatch(setOrigin(origin))
    }

    const accessMethodSelectedCalculated = (dictIsEmpty(accessMethodOptions) || !accessMethod || accessMethod == "")  ? "All": accessMethodOptions[accessMethod].label
    setAccessMethodSelected(accessMethodSelectedCalculated)
    if(accessMethod){
      dispatch(setAccessMethod(accessMethod))
    }

    const formatSelectedCalculated = (dictIsEmpty(formatsFiltersOptions) || !format || format == "")  ? "All": formatsFiltersOptions[format].label
    setFormatSelected(formatSelectedCalculated)
    if(format){
      dispatch(setFormat(format))
      if(formatSelectedCalculated == "GRIB1"){
        setGrib1Selected(true)
      }
      else if(formatSelectedCalculated == "GRIB2"){
        setGrib2Selected(true)
      }
      else if(formatSelectedCalculated == "NetCDF") {
        setNetCDFSelected(true)
      }
    }

    const grib1TableSelectedCalculated = (dictIsEmpty(tableGrib1Options) || !grib1Table || grib1Table == "")  ? "All": tableGrib1Options[grib1Table].name
    setGrib1TableSelected(grib1TableSelectedCalculated)
    if(grib1Table){
      dispatch(setTable(grib1Table))
    }

    const grib2DisciplineSelectedCalculated = (dictIsEmpty(disciplineGrib2Options) || !grib2Discipline || grib2Discipline == "")  ? "All": disciplineGrib2Options[grib2Discipline].name
    setGrib2CategorySelected(grib2DisciplineSelectedCalculated)
    if(grib2Discipline){
      dispatch(setDiscipline(grib2Discipline))
    }

    const grib2CategorySelectedCalculated = (dictIsEmpty(categoriesGrib2Options) || !grib2Category || grib2Category == "")  ? "All": categoriesGrib2Options[grib2Category].name
    setGrib2CategorySelected(grib2CategorySelectedCalculated)
    if(grib2Category){
      dispatch(setCategory(grib2Category))
    }
  }, [accessMethodOptions, formatsFiltersOptions, tableGrib1Options, disciplineGrib2Options, categoriesGrib2Options])

  
  const pageNentries = useSelector(state => state.filters.pageNentries);
  const page = useSelector(state => state.filters.pageNumber);

  var params = getFilterParams()


  if(pageNentries != "All"){
    params['limit'] = pageNentries
    params['offset'] = page*pageNentries
  }
  else{
    delete params['limit']
    delete params['offset']
  }

  const resetPagination = () => {
    if(pageNentries != "All"){
      params['limit'] = pageNentries
      params['offset'] = 0
    }
    else{
      delete params['limit']
      delete params['offset']
    }
    dispatch(setPageNumber(0))
    dispatch(setNEntriesPagination(pageNentries))
  }

  const handleChangeFormatsSelected = (formatSelectedLabel) => { 
    var formatSelected
    resetPagination()

    if(formatSelectedLabel){
      setFormatSelected(formatSelectedLabel)
      formatSelected = formatsFiltersOptionsByLabel[formatSelectedLabel].id
      dispatch(setFormat(formatSelected)) 
      params['encoding'] = formatSelected
      //setSearchParams({ ...searchParams, 'encoding': formatSelected });
      searchParams.has('encoding') ? searchParams.set('encoding', formatSelected): searchParams.append('encoding', formatSelected)
      setSearchParams(searchParams);
    }
    else{
      setFormatSelected('All')
      dispatch(setFormat(''))
      delete params['encoding']
      searchParams.delete('encoding')
      setSearchParams(searchParams);
    }

    switch (formatSelectedLabel) {
      case "GRIB1":
        dispatch(setCategory(""))
        delete params['category']
        dispatch(setDiscipline(""))
        delete params['discipline']
        setGrib1Selected(true);
        setGrib2Selected(false);
        setNetCDFSelected(false);   
        break;
      case "GRIB2":
        dispatch(setTable(""))
        delete params['table']
        searchParams.delete('table')
        setSearchParams(searchParams);

        setGrib1Selected(false);
        setGrib2Selected(true);
        setNetCDFSelected(false);      
        break;
      case "NetCDF":
        dispatch(setTable(""))
        delete params['table']
        searchParams.delete('table')
        setSearchParams(searchParams);

        dispatch(setCategory(""))
        delete params['category']
        searchParams.delete('category')
        setSearchParams(searchParams);

        dispatch(setDiscipline(""))
        delete params['discipline']
        searchParams.delete('discipline')
        setSearchParams(searchParams);

        setGrib1Selected(false);
        setGrib2Selected(false);
        setNetCDFSelected(true);       
        break;
      default:
        dispatch(setTable(""))
        dispatch(setCategory(""))
        dispatch(setDiscipline(""))
        setGrib1Selected(false);
        setGrib2Selected(false);
        setNetCDFSelected(false);  
        break;
    }

    dispatch(setLoading(true))
    getParameters(params)
    .then(response => {
      //isAdmin ? dispatch(setParameters(response.data)) : dispatch(setParameters(response.data.filter(par => par.published)))
      dispatch(setParameters(response.data))
      dispatch(setLoading(false))
    })
  
  }

  const handleResetFiltersSidebar = () => {
    resetPagination()
    
    searchParams.delete('origin')
    searchParams.delete('access')
    searchParams.delete('encoding')
    searchParams.delete('table')
    searchParams.delete('category')
    searchParams.delete('discipline')
    
    setAccessMethodSelected('All')
    dispatch(setAccessMethod(""))
    delete params['access']
    setFormatSelected('All')
    dispatch(setFormat(''))
    delete params['encoding']
    setGrib1TableSelected('All')
    dispatch(setTable(""))
    delete params['table']
    setGrib2CategorySelected('All')
    dispatch(setCategory(""))
    delete params['category']
    setGrib2DisciplineSelected('All')
    dispatch(setDiscipline(""))
    delete params['discipline']
    setGrib1Selected(false);
    setGrib2Selected(false);
    setNetCDFSelected(false);

    setOriginSelected(DEFAULT_ORIGIN_NAME)
    dispatch(setOrigin(DEFAULT_ORIGIN_ID))
    params['origin'] = DEFAULT_ORIGIN_ID

    setSearchParams(
      searchParams
    );

    dispatch(setLoading(true))
    getParameters(params)
    .then(response => {
      //isAdmin ? dispatch(setParameters(response.data)) : dispatch(setParameters(response.data.filter(par => par.published)))
      dispatch(setParameters(response.data))
      dispatch(setLoading(false))
    })
  }

  const handleChangeAccessSelected = (accessMethodSelectedLabel) => {
    resetPagination()

    var accessMethodSelected

    if(accessMethodSelectedLabel){ 
      setAccessMethodSelected(accessMethodSelectedLabel)
      accessMethodSelected = accessMethodOptionsByLabel[accessMethodSelectedLabel].id
      dispatch(setAccessMethod(accessMethodSelected))
      params['access'] = accessMethodSelected
      searchParams.has('access') ? searchParams.set('access', accessMethodSelected): searchParams.append('access', accessMethodSelected)
      setSearchParams(searchParams);
    }
    else{
      setAccessMethodSelected('All')
      dispatch(setAccessMethod(''))
      delete params['access']
      searchParams.delete('access')
      setSearchParams(searchParams);
    }

    dispatch(setLoading(true))
    getParameters(params)
    .then(response => {
      //isAdmin ? dispatch(setParameters(response.data)) : dispatch(setParameters(response.data.filter(par => par.published)))
      dispatch(setParameters(response.data))
      dispatch(setLoading(false))
    })
  }

  const handleChangeOriginSelected = (originSelectedLabel) => {
    resetPagination()

    var originSelected

    if(originSelectedLabel){ 
      setOriginSelected(originSelectedLabel)
      originSelected = originFiltersOptionsByLabel[originSelectedLabel].id
      dispatch(setOrigin(originSelected))
      params['origin'] = originSelected
      searchParams.has('origin') ? searchParams.set('origin', originSelected): searchParams.append('origin', originSelected)
      setSearchParams(searchParams);
    }
    else{
      //setOriginSelected(DEFAULT_ORIGIN_NAME)
      setOriginSelected('All')
      //dispatch(setOrigin(DEFAULT_ORIGIN_ID))
      dispatch(setOrigin(''))
      //params['origin'] = DEFAULT_ORIGIN_ID
      delete params['origin']
      searchParams.has('origin') ? searchParams.set('origin', 'all'): searchParams.append('origin', 'all')
      setSearchParams(searchParams);
    }

    dispatch(setLoading(true))
    getParameters(params)
    .then(response => {
      //isAdmin ? dispatch(setParameters(response.data)) : dispatch(setParameters(response.data.filter(par => par.published)))
      dispatch(setParameters(response.data))
      dispatch(setLoading(false))
    })
  }

  const handleChangeCategoriesSelected = (categorySelectedLabel) => {
    resetPagination()
    
    var categorySelected
    if(categorySelectedLabel){
      categorySelected = categoriesGrib2OptionsByLabel[categorySelectedLabel].id
      setGrib2CategorySelected(categorySelectedLabel)
      dispatch(setCategory(categorySelected))
      params['category'] = categorySelected
      searchParams.has('category') ? searchParams.set('category', categorySelected): searchParams.append('category', categorySelected)
      setSearchParams(searchParams);
    }
    else{
      setGrib2CategorySelected('All')
      delete params['category']
      dispatch(setCategory(""))
      searchParams.delete('discipline')
      setSearchParams(searchParams);
    }

    dispatch(setLoading(true))
    getParameters(params)
    .then(response => {
      //isAdmin ? dispatch(setParameters(response.data)) : dispatch(setParameters(response.data.filter(par => par.published)))
      dispatch(setParameters(response.data))
      dispatch(setLoading(false))
    })
  }

  const handleChangeDisciplineSelected = (disciplineSelectedLabel) => {
    resetPagination()
    var disciplineSelected
    if(disciplineSelectedLabel){
      disciplineSelected = disciplineGrib2OptionsByLabel[disciplineSelectedLabel].id
      setGrib2DisciplineSelected(disciplineSelectedLabel)
      dispatch(setDiscipline(disciplineSelected))
      params['discipline'] = disciplineSelected
      searchParams.has('discipline') ? searchParams.set('discipline', disciplineSelected): searchParams.append('discipline', disciplineSelected)
      setSearchParams(searchParams);
    }
    else{
      setGrib2DisciplineSelected("All")
      delete params['discipline']
      dispatch(setDiscipline(""))
      searchParams.delete('discipline')
      setSearchParams(searchParams);
    }
    dispatch(setLoading(true))
    getParameters(params)
    .then(response => {
      //isAdmin ? dispatch(setParameters(response.data)) : dispatch(setParameters(response.data.filter(par => par.published)))
      dispatch(setParameters(response.data))
      dispatch(setLoading(false))
    })
  }

  const handleChangeTableGrib1Selected = (tableSelectedLabel) => {
    resetPagination()
    var tableSelected
    if(tableSelectedLabel){
      tableSelected = tableGrib1OptionsByLabel[tableSelectedLabel].id
      setGrib1TableSelected(tableSelectedLabel)
      dispatch(setTable(tableSelected))
      params['table'] = tableSelected
      searchParams.has('table') ? searchParams.set('table', tableSelected): searchParams.append('table', tableSelected)
      setSearchParams(searchParams);
    }
    else{
      setGrib1TableSelected("All")
      delete params['table']
      dispatch(setTable(""))
      searchParams.delete('table')
      setSearchParams(searchParams);
    }
    dispatch(setLoading(true))
    getParameters(params)
    .then(response => {
      isAdmin ? dispatch(setParameters(response.data)) : dispatch(setParameters(response.data.filter(par => par.published)))
      dispatch(setLoading(false))
    })
  }


  const getFormatsSelectFilter = () => {
    return (
      <div>
        <div className='filter-header'>
          <span className="text-filters">Format</span>
          <Tooltip title="Lorem Ipsum">
            <IconButton>
              <InfoOutlinedIcon fontSize="small"/>
            </IconButton>
          </Tooltip>
        </div>
        
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={
              Object.keys(formatsFiltersOptions).map(function(key){
                return formatsFiltersOptions[key].label;
            })
          }
          renderInput={(params) => <TextField {...params} size="small" />}
          defaultValue={"All"}
          value={formatSelected}
          onChange={(event, formatSelected) => handleChangeFormatsSelected(formatSelected)}
          />
      </div>
    );
  }

  const getAccessMethodSelectFilter = () => {
    return (
      <div style={{marginTop:35+'px'}}>
        <div className='filter-header'>
          <span style={{marginBottom:"7px"}} className="text-filters">Access Method</span>
          <Tooltip title="Lorem Ipsum">
            <IconButton>
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={
              Object.keys(accessMethodOptions).map(function(key){
                return accessMethodOptions[key].label;
            })
          }
          renderInput={(params) => <TextField {...params} size="small" />}
          defaultValue={"All"}
          value={accessMethodSelected}
          onChange={(event, accessMethodSelected) => handleChangeAccessSelected(accessMethodSelected)}
          />
      </div>
    );
  }

  const getOriginsSelectFilter = () => {
    return (
      <div style={{marginTop:35+'px'}}>
        <div className='filter-header'>

        <span className="text-filters">Origins</span>
        <Tooltip title="Lorem Ipsum">
          <IconButton>
            <InfoOutlinedIcon fontSize="small"/>
          </IconButton>
        </Tooltip>
        </div>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={
              Object.keys(originFiltersOptions).map(function(key){
                return originFiltersOptions[key].name;
            })
          }
          renderInput={(params) => <TextField {...params} size="small" />}
          defaultValue={"All"}
          value={originSelected}
          onChange={(event, orSelected) => handleChangeOriginSelected(orSelected)}
          />
      </div>
    );
  }

  const getPublishLegend = () => {
    return (
      
      <div style={{marginTop:100+'px'}}>

      {/* Header of Legend */}
        <div className='filter-header'>
          <span className="text-filters">Legend: </span>
          <Tooltip title="Published legend">
            <IconButton>
              <InfoOutlinedIcon fontSize="small"/>
            </IconButton>
          </Tooltip>
        </div>

        <Grid container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={0}
              className="legendBox"
        >
          
          <Grid item sx={12}>    
            <Check style={{fontSize: "30px", paddingTop: "15px", float: 'left'}} sx={{ color: "#04A01D" }}/>
            <div className="legendText">This parameter has unpublished representations</div>
          </Grid>

          <Grid item sx={12}>
            <CheckCircle style={{fontSize: "30px", paddingTop: "15px", float: 'left'}} sx={{ color: "#04A01D" }}/>
            <div className="legendText">This parameter has all published representations</div>
          </Grid>

          <Grid item sx={12}>
            <Pending style={{fontSize: "30px", paddingTop: "15px", float: 'left'}} sx={{ color: "#FF9900" }}/>
            <div className="legendText">This parameter has all unpublished representations</div>
          </Grid>

          <Grid item sx={12}>
            <DoNotDisturb style={{fontSize: "30px", paddingTop: "15px", float: 'left'}} sx={{ color: "#DC3545" }}/>
            <div className="legendText">This parameter has been retired</div>
          </Grid>

        </Grid>

      </div>
      
    );
  }


  const getSelectGrib1 = () => {
    return (
      <div>
        <br/>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={
            Object.keys(tableGrib1Options).map(function(key){
              return tableGrib1Options[key].name;
          })
          }
          renderInput={(params) => <TextField {...params} size="small" label="Table"/>}
          defaultValue={"All"}
          value={grib1TableSelected}
          onChange={(event, tableGrib1Selected) => handleChangeTableGrib1Selected(tableGrib1Selected)}
        />
      </div>
    )
  }

  const getDisciplineSelectFilter = () => {
    return (
      <div>
        <br/>
        <Autocomplete
          disablePortal
          id="disc-select-filter"
          options={
            Object.keys(disciplineGrib2Options).map(function(key){
              return disciplineGrib2Options[key].name;
          })
          }
          renderInput={(params) => <TextField {...params} size="small" label="Discipline"/>}
          defaultValue={"All"}
          value={grib2DisciplineSelected}
          onChange={(event, disc) => handleChangeDisciplineSelected(disc)}
        />
      </div>
    );
  }
  const getCategorySelectFilter = () => {
    return (
      <div>
        <br/>
        <Autocomplete
          disablePortal
          id="cat-select-filter"
          options={
            Object.keys(categoriesGrib2Options).map(function(key){
              return categoriesGrib2Options[key].name;
          })
          }
          renderInput={(params) => <TextField {...params} size="small" label="Category"/>}
          defaultValue={"All"}
          value={grib2CategorySelected}
          onChange={(event, cat) => handleChangeCategoriesSelected(cat)}
        />
      </div>
    );
  }


  const getResetToDefault = () => {
    return (
      <div style={{marginTop:55+'px'}}>
        <Button
          sx={{ textTransform: "capitalize", height: 38, width: 100+"%" }}
          variant="outlined"
          onClick={handleResetFiltersSidebar}
        >
          Reset to default
        </Button>

      </div>
    );
  }

  return (
    <div>
      
      {getFormatsSelectFilter()}
      { grib1Selected && getSelectGrib1() }

      { grib2Selected && getDisciplineSelectFilter()}
      { grib2Selected && getCategorySelectFilter()}

      {getAccessMethodSelectFilter()}

      {getOriginsSelectFilter()}

      {getResetToDefault()}

      {isLogged && isAdmin && getPublishLegend()}
      
    </div>
  );
};

export default FiltersTable;