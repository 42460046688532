import React from 'react'

import {UnfoldLess, UnfoldMore } from "@mui/icons-material";

const expandedStatus = {
    true : <UnfoldLess/>,
    false : <UnfoldMore/>
}

function UnFoldIcon({ expanded }) {
    return expandedStatus[expanded]
}

export default UnFoldIcon