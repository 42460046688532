import './../index.css';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setParameters, setPageNumber, setLoading, setOrderBy, setParameterDetail, setParamDetailNetCDF, setParamDetailOtherGrib2, setParamDetailDefaultGrib2, setParamDetailOtherGrib1, setParamDetailDefaultGrib1, setClone } from '../actions';

import{ORDERED_BY_ID_ASC, ORDERED_BY_ID_DESC, ORDERED_BY_NAME_ASC, ORDERED_BY_NAME_DESC,ORDERED_BY_SHORT_NAME_ASC,ORDERED_BY_SHORT_NAME_DESC,ORDERED_BY_UNIT_NAME_ASC,ORDERED_BY_UNIT_NAME_DESC } from '../constDefinition';

//import Modal from './modal/Modal';
import FiltersTable from './FiltersTable';
import HeaderTablePage from './HeaderTablePage';
import { Alert, Button, Divider, Grid, Snackbar } from '@mui/material';
import Chip from '@mui/material/Chip';
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import Stack from '@mui/material/Stack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { tooltipClasses } from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import ArrowIcon from './ArrowIcon';
import { deleteParameter, retireParameter } from '../services/ParameterService';
import { borders } from '@mui/system';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { getParameterDetail, getParameterDetailEncoding, getParameters } from '../services/ParameterService';
import { getFilterParams } from '../composeParameters';
import { Check, CheckCircle, Clear, ContentCopy, Create, Delete, DoNotDisturb, Pending } from '@mui/icons-material';

const ParametersTable = () => {

  // Add+/clone status

  // const [form, setForm] = useState();
  // const cloneButton = () => setForm(2);

  const dispatch = useDispatch()
  const navigate = useNavigate();
  var isLogged = useSelector(state => state.auth.logged)
  var isAdmin = useSelector(state => state.auth.admin)

  const storeLoading = useSelector(state => state.filters.loading);
  
  const pageNentries = useSelector(state => state.filters.pageNentries);
  const units_definition = useSelector(state => state.definition.units);
  const encoding_definition = useSelector(state => state.definition.encoding);
  const access_definition = useSelector(state => state.definition.access);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const [parameterSelected, setParameterSelected] = React.useState(null);

  const table = useSelector(state => state.parameters.parameters);
  console.log(table);
  const totalRows = useSelector(state => state.parameters.parametersCount);
  const page = useSelector(state => state.filters.pageNumber);
  console.log(page);

  const [messageSnack, setMessageSnack] = React.useState({
    open: false,
    message: "",
    severity: "success"
  });
  const { open, message, severity } = messageSnack;
  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessageSnack({open: false, message: "", severity: "success"});
  };
  
  const [searchParams, setSearchParams] = useSearchParams()
  const ordering = searchParams.get("ordering")
  dispatch(setOrderBy(ordering))


  var lastOrdering = {ordering: ordering, arrow: 'asc'}
  const [sortOrderId, setSortOrderId] = useState('asc');
  const [sortOrderName, setSortOrderName] = useState('asc');
  const [sortOrderShortName, setSortOrderShortName] = useState('asc');
  const [sortOrderUnits, setSortOrderUnits] = useState('asc');
  const [ArrowIdColor, setArrowIdColor] = useState(1);
  const [ArrowNameColor, setArrowNameColor] = useState(0);
  const [ArrowShortNameColor, setArrowShortNameColor] = useState(0);
  const [ArrowUnitsColor, setArrowUnitsColor] = useState(0);

  var params = getFilterParams()

  

  /*
  useEffect(() => {
    const handleTabClose = event => {
      event.preventDefault();
      console.log('beforeunload event triggered');
      return (event.returnValue = 'Are you sure you want to exit?');
    };


    window.addEventListener('beforeunload', handleTabClose);

    //getDataFromTable();

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, [])

  */

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    //border: '1px solid #000',
    boxShadow: 12,
  };


  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#ffffff',
      color: 'rgba(0, 0, 0, 1)',
      maxWidth: 520,
      fontSize: theme.typography.pxToRem(15),
      border: '1px solid #F0F0F0',
    },
  }));

  const getPublishedStatus = (row) => {
    var iconPublished
     if(row.retired){
      iconPublished = 
      <Tooltip title="This parameter has been retired">
        <DoNotDisturb fontSize="medium" sx={{ color: "#DC3545" }}/>
      </Tooltip>
    }
    else if(row.published && row.pending){
      iconPublished = 
      <Tooltip title="This parameter has unpublished representations">
        <Check fontSize="medium" sx={{ color: "#04A01D" }}/>
      </Tooltip>
    }
    else if(row.published){
      iconPublished = 
      <Tooltip title="This parameter has all published representations">
        <CheckCircle fontSize="medium" sx={{ color: "#04A01D" }}/>
      </Tooltip>
    }
    else if(row.pending){
      iconPublished =
      <Tooltip title="This parameter has all unpublished representations">
       <Pending fontSize="medium" sx={{ color: "#FF9900" }}/>
      </Tooltip>
    }
    else{
      iconPublished = 
      <Tooltip title="This parameter has all unpublished representations">
       <Pending fontSize="medium" sx={{ color: "#FF9900" }}/>
      </Tooltip>
    }

    return (
      <StyledTableCell align="center">
        {iconPublished}
      </StyledTableCell>
    )
  }

  const deleteParameterFromTable = (id) => {
    handleClose()
    deleteParameter(id)
      .then(
        (response) => {
          setMessageSnack({open: true, message: "Parameter succesfully deleted", severity: "success"})
          params = getFilterParams()
          console.log("refreshParameter")
          // refreshParameter()
          refresh()
        }
      )
      .catch((error) => {
        setMessageSnack({open: true, message: "ERROR: "+error.response.data.detail, severity: "error"})
        params = getFilterParams()
        // refreshParameter()
        refresh()
      })
  }

  const refresh = () => window.location.reload(true);

  const retireParameterFromTable = (id) => {
    handleClose()
    retireParameter(id)
    .then(
        (response) => {
          setMessageSnack({open: true, message: "Parameter succesfully retired", severity: "success"})
          params = getFilterParams()
          console.log("refreshParameter")
          // refreshParameter()
          refresh()
        }
      )
      .catch((error) => {
        setMessageSnack({open: true, message: "ERROR: "+error.response.data.detail, severity: "error"})
        params = getFilterParams()
        refreshParameter()
      })
  }

  const cloneParameter = (id) => {
    Promise.all([ 
      getParameterDetailEncoding(id, "grib1"),
      getParameterDetailEncoding(id, "grib2"),
      getParameterDetailEncoding(id, "netcdf"),
      getParameterDetail(id)
    ]).then((responses) => {
      const grib1EncodingDefaultReceived = responses[0].data.filter(enc => (enc.default))
      const grib1EncodingOtherReceived = responses[0].data.filter(enc => (!enc.default))
      dispatch(setParamDetailDefaultGrib1(grib1EncodingDefaultReceived))
      dispatch(setParamDetailOtherGrib1(grib1EncodingOtherReceived))

      const grib2EncodingDefaultReceived = responses[1].data.filter(enc => (enc.default))
      const grib2EncodingOtherReceived = responses[1].data.filter(enc => (!enc.default))
      dispatch(setParamDetailDefaultGrib2(grib2EncodingDefaultReceived))
      dispatch(setParamDetailOtherGrib2(grib2EncodingOtherReceived))

      const netCDFEncodingReceived = responses[2].data.filter(enc => (enc.published))
      dispatch(setParamDetailNetCDF(netCDFEncodingReceived))

      dispatch(setParameterDetail(responses[3].data)) 
      
      dispatch(setClone(true))
      navigate("/parameter-create");
    })
  }

  const getActions = (id) => {
    return (
      <StyledTableCell align="center" class="action-buttons">

        <IconButton aria-label="edit" component="label">
          <Link style={{padding: '5px'}} className="linkEdit" to={"/parameter-edit/"+id}><Create fontSize="medium" color="primary"/></Link>
        </IconButton>

        <IconButton aria-label="copy" component="label">
          <ContentCopy style={{padding: '5px'}} onClick={() => cloneParameter(id)} fontSize="medium" color="primary"/>
        </IconButton>

        <IconButton aria-label="delete" component="label">
          <Delete
            style={{padding: '5px'}}
           fontSize="medium"
           onClick={() => {
            setParameterSelected(id)
            handleOpen()
           }} 
           sx={{ color: "#DC3545" }}
           />
        </IconButton>

      </StyledTableCell>
    )
  }


  const getFullName = (row, value) => {
    return (
      
        <StyledTableCell align="left">
          <HtmlTooltip
            title={
              <React.Fragment>
                <div><Typography margin={"-8px; -6px"} padding={"25px; 16px"} backgroundColor="#F0F0F0" color="inherit">{value} // {row.shortname} (ID #{row.id})</Typography></div>
                <div style={{padding: "16px"}} dangerouslySetInnerHTML={{ __html: row.description }}></div>
              </React.Fragment>
            }
            placement="bottom"
            arrow
          >
          <div style={{ fontSize: "15px", margin: "0px", maxWidth: "300px" }}>
            <Link to={"/parameter-detail/"+row.id}>{value}</Link>
          </div>
          </HtmlTooltip>
          
        </StyledTableCell>
    )
  }


  const getCellValue = (value) => {
    return (
      <StyledTableCell align="left">
        <p style={{ fontSize: "15px", margin: "0px" }}>{value}     
        </p>
      </StyledTableCell>
    )
  }

  const getCellMultipleChips = (values) => {
    return (
      <StyledTableCell align="left">
        <Stack direction="row" spacing={1}>
        {
          values.map(value => (
              <Chip
                size="small"
                label={value.label}
              />
          ))
        }
        </Stack>
      </StyledTableCell>
    )
  }


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13,
      fontWeight: 500
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.action.hover,
      backgroundColor: '#F8F8F8'
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    height: "36px"
  }));


  const handleChangePage = (event, newPage) => {
    console.log(newPage)
    newPage= newPage-1;
    if(pageNentries != "All"){
      params['limit'] = pageNentries
      params['offset'] = newPage*pageNentries
    }
    else{
      delete params['limit']
      delete params['offset']
    }

    dispatch(setPageNumber(newPage))
    dispatch(setLoading(true))
    refreshParameter()
  }

  const getTablePagination = () => {
    return (
      <TablePagination
        rowsPerPageOptions={[5]}
        component="div"
        count={totalRows}
        rowsPerPage={pageNentries == "All" ? totalRows :parseInt(pageNentries)}
        page={page}
        onPageChange={handleChangePage}
      />
 
      );
  }

  const showParameterNumbers = () => {
    params['limit'] = pageNentries
    params['offset'] = page*pageNentries
    return(
      <div style={{width: '400px', float: 'left', paddingLeft: '50px', marginTop: '30px'}}>
      <p><strong>Showing {page*pageNentries + 1} to {Number(page*pageNentries) + Number(pageNentries)} of {totalRows} (filtered from '5821' total entries)</strong></p>
      <p><em>'Last updated on the 25 Feb 2022'</em></p>
      </div>
    )
  }

  const PaginationRounded = () => {
    return (
      <Pagination 
        rowsPerPageOptions={[5]}
        component="div"
        className="paginator"
        count={parseInt(totalRows/pageNentries)} 
        color="primary"
        shape="rounded" 
        defaultPage={page+1}
        rowsPerPage={pageNentries == "All" ? totalRows :parseInt(pageNentries)}
        page={page+1}
        onChange={handleChangePage}
        
        >
          <PaginationItem
          selected={true}
          />
        </Pagination>
    );
  }


  const resetArrows = () => {
    setSortOrderId('asc')
    setSortOrderName('asc')
    setSortOrderShortName('asc')
    setSortOrderUnits('asc')
    setArrowIdColor(0);
    setArrowNameColor(0);
    setArrowShortNameColor(0);
    setArrowUnitsColor(0);
  }

  const getSortIcon = (sortBy) => {
    switch(sortBy){
      case "id":
        if (lastOrdering['ordering'] == ORDERED_BY_ID_ASC){
          setSortOrderId(sortOrderId === 'asc' ? 'desc' : 'asc');
          return ORDERED_BY_ID_DESC
        }else if(lastOrdering['ordering'] == ORDERED_BY_ID_DESC){
          setSortOrderId(sortOrderId === 'asc' ? 'desc' : 'asc');
          return ORDERED_BY_ID_ASC
        }else{
          resetArrows();
          setArrowIdColor(1);
          lastOrdering['ordering'] = ORDERED_BY_ID_ASC
          return ORDERED_BY_ID_ASC
        }
      case "name":
        if (lastOrdering['ordering'] == ORDERED_BY_NAME_ASC){
          setSortOrderName(sortOrderName === 'asc' ? 'desc' : 'asc');
          return ORDERED_BY_NAME_DESC
        }else if(lastOrdering['ordering'] == ORDERED_BY_NAME_DESC){
          setSortOrderName(sortOrderName === 'asc' ? 'desc' : 'asc');
          return ORDERED_BY_NAME_ASC
        }else{
          resetArrows();
          setArrowNameColor(1);
          lastOrdering['ordering'] = ORDERED_BY_NAME_ASC
          return ORDERED_BY_NAME_ASC
        }
        break;
      case "shortname":
        if (lastOrdering['ordering'] == ORDERED_BY_SHORT_NAME_ASC){
          setSortOrderShortName(sortOrderShortName === 'asc' ? 'desc' : 'asc');
          return ORDERED_BY_SHORT_NAME_DESC
        }else if(lastOrdering['ordering'] == ORDERED_BY_SHORT_NAME_DESC){
          setSortOrderShortName(sortOrderShortName === 'asc' ? 'desc' : 'asc');
          return ORDERED_BY_SHORT_NAME_ASC
        }else{
          resetArrows();
          setArrowShortNameColor(1);
          lastOrdering['ordering'] = ORDERED_BY_SHORT_NAME_ASC
          return ORDERED_BY_SHORT_NAME_ASC
        }
        break;
      case "units":
        if (lastOrdering['ordering'] == ORDERED_BY_UNIT_NAME_ASC){
          setSortOrderUnits(sortOrderUnits === 'asc' ? 'desc' : 'asc');
          return ORDERED_BY_UNIT_NAME_DESC
        }else if(lastOrdering['ordering'] == ORDERED_BY_UNIT_NAME_DESC){
          setSortOrderUnits(sortOrderUnits === 'asc' ? 'desc' : 'asc');
          return ORDERED_BY_UNIT_NAME_ASC
        }else{
          resetArrows();
          setArrowUnitsColor(1);
          lastOrdering['ordering'] = ORDERED_BY_UNIT_NAME_ASC
          return ORDERED_BY_UNIT_NAME_ASC
        }
        break;
      }
  }

  const refreshParameter = () => {
    getParameters(params)
      .then(response => {
        dispatch(setParameters(response.data));
        dispatch(setLoading(false));
      });
  }
  

  const getSortBy = (sortBy) =>{
    console.log(sortBy)
    sortBy = getSortIcon(sortBy)
    console.log(sortBy)
    params['ordering'] = sortBy

    searchParams.has('ordering') ? searchParams.set('ordering', sortBy): searchParams.append('ordering', sortBy)
    setSearchParams(searchParams);

    if(pageNentries != "All"){
      params['limit'] = pageNentries
      params['offset'] = 0
    }
    else{
      delete params['limit']
      delete params['offset']
    }
    dispatch(setOrderBy(sortBy))
    dispatch(setLoading(true))
    refreshParameter();
  }

  const getRowId = (row) => {
    return row.shortname+"_"+row.id
  }

  if(encoding_definition && access_definition && units_definition){
    return (
      <div>
        <HeaderTablePage/>
        <Grid container spacing={0}>
          <Grid item md={2} px={4} pt={3}>
            <FiltersTable/>
          </Grid>
          <Grid item md={10} px={4}>
            <div className='table-style'>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      {isLogged && isAdmin &&
                        <StyledTableCell align="center"><p className="header-table-text">Published</p></StyledTableCell>
                      }
                      <StyledTableCell>
                        <span className="header-table-text">Name</span>
                        <IconButton onClick={() =>{
                          getSortBy('name')
                        }}><ArrowIcon direction={sortOrderName} color={ArrowNameColor}/></IconButton>
                      </StyledTableCell>
                      
                      <StyledTableCell>
                        <span className="header-table-text">Short name</span>
                        <IconButton onClick={() =>{
                          getSortBy('shortname')
                        }}><ArrowIcon direction={sortOrderShortName} color={ArrowShortNameColor} /></IconButton>
                      </StyledTableCell>

                      <StyledTableCell>
                        <span className="header-table-text">Units</span>
                        <IconButton onClick={() =>{
                          getSortBy('units')
                        }}><ArrowIcon direction={sortOrderUnits} color={ArrowUnitsColor}/></IconButton>
                      </StyledTableCell>
                      
                      <StyledTableCell>
                        <span className="header-table-text">ID</span>
                        <IconButton onClick={() =>{
                          getSortBy('id')
                        }}><ArrowIcon direction={sortOrderId} color={ArrowIdColor} /></IconButton>
                        
                      </StyledTableCell>

                      <StyledTableCell>
                        <p className="header-table-text">Format</p>
                      </StyledTableCell>

                      <StyledTableCell><span className="header-table-text">Access method</span>
                        <Tooltip title="Lorem Ipsum">
                          <IconButton>
                            <InfoOutlinedIcon fontSize="small"/>
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>

                      {isLogged && isAdmin &&
                        <StyledTableCell align="center"><p className="header-table-text">Actions</p></StyledTableCell>
                      }
                    </TableRow> 
                  </TableHead>
                  <TableBody>
                  {
                    storeLoading?     <Box sx={{ display: 'flex' }}><CircularProgress /></Box>
                    :
                      table.length <= 0?
                      <div className='not-found'><span className='not-found-text'><h2>"oh oh, your search criteria seem too narrow and no results can be found. Please try broadening your criteria and search again"</h2></span></div>
                      :
                      table.map(row => 
                            <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }}} key={getRowId(row)}>
                              {isLogged && isAdmin && getPublishedStatus(row)}
                              {getFullName(row, row.name)}
                              {getCellValue(row.shortname)}
                              {getCellValue(units_definition && units_definition[row.unit_id] ? units_definition[row.unit_id].name : "")}
                              {getCellValue(row.id)}
                              {getCellMultipleChips(Object.values(encoding_definition).filter(def => row.encoding_ids.includes(def.id)))}
                              {getCellMultipleChips(Object.values(access_definition).filter(def => row.access_ids.includes(def.id)))}
                              {isLogged && isAdmin && getActions(row.id)}
                            </StyledTableRow >
                          )
                  }

                  </TableBody>
                </Table>
                {showParameterNumbers()}
                {PaginationRounded()}
              </div>
          </Grid>

          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box sx={{ justifyContent: 'space-between',  display: 'flex' }}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{p:2}}>
                  Please confirm
                </Typography>
                <IconButton sx={{ marginRight: "13px" }} style={{ width: "35px", height: "35px", marginTop: "15px"}} aria-label="delete" component="label">
                  <Clear onClick={handleClose}/>
                </IconButton>
              </Box>
              <Divider></Divider>
              <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, p:2 }}>
                What do you want to do?
              </Typography>
              <Divider></Divider>
              <Box sx={{ justifyContent: 'space-between',  display: 'flex', p:2 }}>
                <Button
                  variant="outlined"
                  sx={{textTransform: "none", borderColor: "black", color: "black"}}
                  onClick={handleClose} 
                  >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  sx={{textTransform: "none"}} 
                  onClick={() => retireParameterFromTable(parameterSelected)}
                  >
                  Retire this parameter
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  sx={{textTransform: "none"}} 
                  onClick={() => deleteParameterFromTable(parameterSelected)}
                  >
                  Delete this parameter
                </Button>
              </Box>
            </Box>
          </Modal>
        </Grid>
        <Snackbar open={open} autoHideDuration={4000} onClose={handleCloseSnack} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Alert onClose={handleCloseSnack} severity={severity} sx={{ width: '100%', boxShadow: 'none', borderRadius: '6px' }}>
            {message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
};

export default ParametersTable;


