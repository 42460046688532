import store from "./store";

export function getFilterParams() {
    const state = store.getState();
    var params = {}
    const search = state.filters.search
    if(search !== ""){
        params['search'] = search
    }
    const accessMethod = state.filters.accessMethod
    if(accessMethod !== ""){
        params['access'] = accessMethod
    }
    const format = state.filters.format
    if(format !== ""){
        params['encoding'] = format
    }
    const tableGrib1 = state.filters.table
    if(tableGrib1 !== ""){
        params['table'] = tableGrib1
    }
    const category = state.filters.category
    if(category !== ""){
        params['category'] = category
    }
    const discipline = state.filters.discipline
    
    if(discipline !== ""){
        params['discipline'] = discipline
    }

    const origin = state.filters.origin
    if(origin !== "" && origin != 'all'){
        params['origin'] = origin
    }
    const ordering = state.filters.ordering
    if(ordering !== ""){
        params['ordering'] = ordering
    }

    const regex = state.filters.regex
    params['regex'] = regex
    
    console.log(params)
    return params
  }