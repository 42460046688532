import axios from "axios";
import { baseURL } from "./constDefinitionService";

const url = "unit/";

const client = axios.create({
    baseURL: baseURL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    withCredentials: true
  });

export function getUnits(){
    return client.get(
        url+'?ordering=name'
    )
}

export function createUnit(unit){
    return client.post(
        url,
        unit
    )
}
  
