import axios from "axios";
import { baseURL, baseURL_login } from "./constDefinitionService";

const client = axios.create({
    baseURL: baseURL_login,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    withCredentials: true
  });

export function checkLogin(){
    return client.get(
        "frontend/config"
    )
}

export function whoami(){
    return client.get(
        "/parameter-database/api/v1/whoami/"
    )
}
  