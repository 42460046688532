import { Route, BrowserRouter as Router, Switch, BrowserRouter, Routes, useRoutes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect, useState } from 'react';
import { getAccess } from './services/AccessService';
import { getUnits } from './services/UnitService';
import { getTables } from './services/TableService';
import { getEncoding } from './services/EncodingService';
import { getOrigins } from './services/OriginService';
import { getDisciplines } from './services/DisciplineService';
import { getCategories } from './services/CategoryService';
import { checkLogin, whoami } from './services/LoginCheckService';
import { setCategories, setDisciplines, setEncoding, setOrigins, setUnits, setTables, notLogged, logged, setAccess, setParameters, setLoading, setAdmin } from './actions';
import Home from './pages/Home';
import ParameterDetail from './pages/ParameterDetail';
import { getParameters } from './services/ParameterService';
import { PARAMETER_PAGE_LIMIT } from './constDefinition';
import ParameterCreate from './pages/ParameterCreate';
import { getFilterParams } from './composeParameters';
import ParameterEdit from './pages/ParameterEdit';

function App() {

  const dispatch = useDispatch()

  const [isAdmin, setIsAdmin] = useState(false);
  
  // dispatch(logged())
  // dispatch(setAdmin(true))

  whoami()
    .then(
      response => {
        if(response.data.admin){
          dispatch(logged())
          dispatch(setAdmin(true))
          setIsAdmin(true)
        }
        else{
          dispatch(setAdmin(false))
          setIsAdmin(false)
        }
      }
    )


  useEffect(() => {
      var params = getFilterParams();
      params["limit"] = PARAMETER_PAGE_LIMIT;
      params["offset"] = 0;
      dispatch(setLoading(true))
      getParameters(params)
      .then(response => {
        console.log(response.data)
        //isAdmin ? dispatch(setParameters(response.data)) : dispatch(setParameters(response.data.filter(par => par.published)))
        dispatch(setParameters(response.data))
        dispatch(setLoading(false))
      })

      getAccess()
      .then(response => {
          dispatch(setAccess(response.data)) 
      })

      getUnits()
      .then(response => {
          dispatch(setUnits(response.data)) 
      })

      getTables()
      .then(response => {
          dispatch(setTables(response.data)) 
      })

      getEncoding()
      .then(response => {
          dispatch(setEncoding(response.data)) 
      })

      getOrigins()
      .then(response => {
          dispatch(setOrigins(response.data)) 
      })

      getDisciplines()
      .then(response => {
          dispatch(setDisciplines(response.data)) 
      })

      getCategories()
      .then(response => {
          dispatch(setCategories(response.data)) 
      })

    
}, [])

// let routes = [
//   {
//     path: '/',
//     element: <Home />,
//     children: [
//       { index: true, element: <Home /> },
//       {
//         path: "parameter-detail/:id",
//         element: <ParameterDetail />,
//       },
//     ],
//   },
// ];

// let element = useRoutes(routes);
// const breadcrumbs = useBreadcrumbs();

  return (
    <>
    {/* <div>
      <p style={{ color: 'red' }}>
        {breadcrumbs.map(({ breadcrumb, match }, index) => (
          <>
            <NavLink key={match.pathname} to={match.pathname}>
              {breadcrumb}
            </NavLink>
            {index !== breadcrumbs.length - 1 && '/'}
          </>
        ))}
      </p>
      
      {element}
    </div> */}

      <BrowserRouter>
        <Routes>
          <Route path='' element={<Home/>}/>
          {/* <Route path={"parameter-detail/?id=:id"} element={<ParameterDetail/>}/> */}
          <Route path={"parameter-detail/:id"} element={<ParameterDetail/>}/>
          <Route path={"parameter-create"} element={<ParameterCreate/>}/>
          <Route path={"parameter-edit/:id"} element={<ParameterEdit/>}/>
        </Routes>
      </BrowserRouter>
  </>
  );
}

export default App;
