import axios from "axios";
import { baseURL } from "./constDefinitionService";

const url = "param/";

const client = axios.create({
    baseURL: baseURL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    withCredentials: true
  });

export function getParameters(params){
    return client.get(
        url,
        {params: params}
    )
}
  
export function getParameterDetail(id){
    return client.get(
        url+id
    )
}

export function getParameterDetailEncoding(id, encoding){
    return client.get(
        url+id+"/"+encoding
    )
}

export function createParameter(data){
    return client.post(
        url,
        data
    )
}

export function createRepresentation(parameter_id, encoding_type, data){
    return client.post(
        url+parameter_id+"/"+encoding_type+"/",
        data
    )
}

export function updateRepresentation(parameter_id, encoding_id, encoding_type, data){
    return client.put(
        url+parameter_id+"/"+encoding_type+"/"+encoding_id+"/",
        data
    )
}

export function deleteRepresentation(parameter_id, encoding_id, encoding_type){
    return client.delete(
        url+parameter_id+"/"+encoding_type+"/"+encoding_id+"/"
    )
}

export function publishRepresentation(parameter_id, encoding_id, encoding_type){
    ///param/{param_id}/{grib1|grib2|netcdf}/{encoding_id}/publish/ (POST)
    return client.post(
        url+parameter_id+"/"+encoding_type+"/"+encoding_id+"/publish/"
    )
}

export function unpublishRepresentation(parameter_id, encoding_id, encoding_type){
    ///param/{param_id}/{grib1|grib2|netcdf}/{encoding_id}/publish/ (POST)
    return client.delete(
        url+parameter_id+"/"+encoding_type+"/"+encoding_id+"/publish/"
    )
}

export function updateParameter(parameter_id, data){
    return client.put(
        url+parameter_id+"/",
        data
    )
}

export function deleteParameter(parameter_id){
    return client.delete(
        url+parameter_id
    )
}

export function retireParameter(parameter_id){
    return client.post(
        url+parameter_id+"/retire/"
    )
}