import { SET_CLONE, SET_PARAMETER_SELECTED, SET_PARAMETER_SELECTED_GRIB1_DEFAULT_ENCODINGS, SET_PARAMETER_SELECTED_GRIB1_OTHER_ENCODINGS, SET_PARAMETER_SELECTED_GRIB2_DEFAULT_ENCODINGS, SET_PARAMETER_SELECTED_GRIB2_OTHER_ENCODINGS, SET_PARAMETER_SELECTED_NETCDF_ENCODINGS, SET_PARAMETER_SELECTED_NETCDF_OTHER_ENCODINGS } from "../constDefinition";

const initialState = {
  clone: false,
  parameterSelected: undefined,
  parameterSelectedGrib1DefaultEncodings: [],
  parameterSelectedGrib1OtherEncodings: [],
  parameterSelectedGrib2DefaultEncodings: [],
  parameterSelectedGrib2OtherEncodings: [],
  parameterSelectedNetCDFEncodings: [],
}

const paramDetailReducer = (state = initialState, action) => {
  switch(action.type)
  {
    case SET_CLONE:
      return { 
        ...state, 
        clone: action.value,
      }
    case SET_PARAMETER_SELECTED:
      return { 
        ...state, 
        parameterSelected: action.value,
      }
    case SET_PARAMETER_SELECTED_GRIB1_DEFAULT_ENCODINGS:
      return { 
        ...state, 
        parameterSelectedGrib1DefaultEncodings: action.value,
      }
    case SET_PARAMETER_SELECTED_GRIB1_OTHER_ENCODINGS:
      return { 
        ...state, 
        parameterSelectedGrib1OtherEncodings: action.value,
      }
    case SET_PARAMETER_SELECTED_GRIB2_DEFAULT_ENCODINGS:
      return { 
        ...state, 
        parameterSelectedGrib2DefaultEncodings: action.value,
      }
    case SET_PARAMETER_SELECTED_GRIB2_OTHER_ENCODINGS:
      return { 
        ...state, 
        parameterSelectedGrib2OtherEncodings: action.value,
      }
    case SET_PARAMETER_SELECTED_NETCDF_ENCODINGS:
      return { 
        ...state, 
        parameterSelectedNetCDFEncodings: action.value,
      }
    default: return state;
  }
};

export default paramDetailReducer;
