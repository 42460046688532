import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  Table,
  TableCell,
  TableBody,
  TableRow
} from "@material-ui/core";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { setClone, setLoading, setParamDetailDefaultGrib1, setParamDetailDefaultGrib2, setParamDetailNetCDF, setParamDetailOtherGrib1, setParamDetailOtherGrib2, setParamDetailOtherNetCDF, setParameterDetail } from '../actions';
import { deleteParameter, getParameterDetail, getParameterDetailEncoding } from '../services/ParameterService';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import logo from '../assets/ecmwf.png'
import { Chip, Divider, FormControl, IconButton, InputLabel, MenuItem, Modal, Stack, TableFooter } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Clear, Navigation } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Snackbar from '@mui/material/Snackbar';
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { Container, shadows } from '@mui/system';



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ParameterDetail = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  var isLogged = useSelector(state => state.auth.logged)
  var isAdmin = useSelector(state => state.auth.admin)

  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenModalDelete(true);
  const handleCloseDelete = () => setOpenModalDelete(false);
  const [parameterSelected, setParameterSelected] = React.useState(null);

  // const [openModal, setOpenModal] = React.useState(false);
  // const handleOpen = () => setOpenModal(true);
  // const handleClose = () => setOpenModal(false);

  const params = useParams();

  const [paramDetail, setParamDetail] = useState();

  const [grib1DefaultEncodings, setGrib1DefaultEncodings] = useState([]);
  const [grib1OtherEncodings, setGrib1OtherEncodings] = useState([]);

  const [grib2DefaultEncodings, setGrib2DefaultEncodings] = useState([]);
  const [grib2OtherEncodings, setGrib2OtherEncodings] = useState([]);

  const [netCDFEncodings, setNetCDFEncodings] = useState([]);

  const storeLoading = useSelector(state => state.filters.loading);

  useEffect(() => {
    dispatch(setLoading(true))

    Promise.all([ 
      getParameterDetailEncoding(params.id, "grib1"),
      getParameterDetailEncoding(params.id, "grib2"),
      getParameterDetailEncoding(params.id, "netcdf"),
      getParameterDetail(params.id)
    ]).then((responses) => {

      console.log(responses[0])
      console.log(responses[1])
      console.log(responses[2])
      console.log(responses[3])
      //getParameterDetailEncoding(params.id, "grib1")
      const grib1EncodingDefaultReceived = responses[0].data
      .filter(enc => {
        return isAdmin ? enc.default : enc.published && enc.default
      })
      const grib1EncodingOtherReceived = responses[0].data
      .filter(enc => {
        return isAdmin ? !enc.default : enc.published && !enc.default
      })
      setGrib1DefaultEncodings(grib1EncodingDefaultReceived)
      dispatch(setParamDetailDefaultGrib1(grib1EncodingDefaultReceived))
      setGrib1OtherEncodings(grib1EncodingOtherReceived)
      dispatch(setParamDetailOtherGrib1(grib1EncodingOtherReceived))


      //getParameterDetailEncoding(params.id, "grib2")
      const grib2EncodingDefaultReceived = responses[1].data
        .filter(enc => {
          console.log(enc)
          console.log(isAdmin ? enc.default : enc.published && enc.default)
          return isAdmin ? enc.default : enc.published && enc.default 
        })
      const grib2EncodingOtherReceived = responses[1].data
      .filter(enc => {
          console.log(enc)
          console.log(isAdmin)
          console.log(isAdmin ? !enc.default : enc.published && !enc.default)
          return isAdmin ? !enc.default : enc.published && !enc.default
      })

      setGrib2DefaultEncodings(grib2EncodingDefaultReceived)
      dispatch(setParamDetailDefaultGrib2(grib2EncodingDefaultReceived))
      setGrib2OtherEncodings(grib2EncodingOtherReceived)
      dispatch(setParamDetailOtherGrib2(grib2EncodingOtherReceived))

      //getParameterDetailEncoding(params.id, "netcdf")
      const netCDFEncodingReceived = responses[2].data
        .filter(enc => {
          return isAdmin ? true : enc.published
        })
      setNetCDFEncodings(netCDFEncodingReceived)
      dispatch(setParamDetailNetCDF(netCDFEncodingReceived))

      if(grib2EncodingDefaultReceived.length + grib2EncodingOtherReceived.length > 0){
        setTab('grib2')
      }
      else if(grib1EncodingDefaultReceived.length + grib1EncodingOtherReceived.length > 0){
        setTab('grib1')
      }
      else if(netCDFEncodingReceived.length > 0){
        setTab('netCDF')
      }
      else {
        setTab('grib2')
      }

      //getParameterDetail(params.id)
      dispatch(setParameterDetail(responses[3].data)) 
      setParamDetail(responses[3].data)
      dispatch(setClone(false))
      dispatch(setLoading(false))
    })
  }, [isAdmin])

  const units_definition = useSelector(state => state.definition.units);
  const encoding_definition = useSelector(state => state.definition.encoding);
  const access_definition = useSelector(state => state.definition.access);
  const origin = useSelector(state => state.definition.origins);
  
  const [showMoreOrLess, setShowMoreOrLess] = useState(true);
  const [tab, setTab] = useState('grib2');

  const [messageSnack, setMessageSnack] = React.useState({
    open: false,
    message: "",
    severity: "success"
  });
  const { open, message, severity } = messageSnack;
  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessageSnack({open: false, message: "", severity: "success"});
  };


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgba(49, 108, 244, 0.1)",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    },
  }));

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 12,
  };

  const StyledOddTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#F8F8F8'
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const StyledFullToneTableRow = styled(TableRow)(({ theme }) => ({
  '&': {
      // backgroundColor: theme.palette.action.hover,
      backgroundColor: '#F8F9FA'
      },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
      },
  }));

  const StyledFullToneTableRowUnpublish = styled(TableRow)(({ theme }) => ({
    '&': {
        // backgroundColor: theme.palette.action.hover,
        // Giallo chiaro
        backgroundColor: "#FFF9E5"
        },
      // hide last border
      '&:last-child td, &:last-child th': {
        border: 0,
        },
    }));
  
  const StyledFooterTableRow = styled(TableRow)(({ theme }) => ({
    '&': {
      // backgroundColor: theme.palette.action.hover,
      backgroundColor: '#EFF2F6',
    }
  }));

  const StyledFooterTableRowUnpublish = styled(TableRow)(({ theme }) => ({
    '&': {
      // backgroundColor: theme.palette.action.hover,
      backgroundColor: "#FCF2D0"

    }
  }));

  const StyledWarningTableRow = styled(TableRow)(({ theme }) => ({
    '&': {
      // backgroundColor: theme.palette.action.hover,
      // backgroundColor: '#EFF2F6'
      // Giallo scuro
      backgroundColor: "#FCF2D0",
    }
  }));

  const getRowTopTable = (leftHeader, rightValue) => {
    return (
      <StyledOddTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, "height": '35px'}} >

        <StyledTableCell align="right">
          <p style={{ width: "16em", fontSize: "15px", margin: "0px" }}>{leftHeader}</p>
        </StyledTableCell>
        
        <StyledTableCell align="left">
          {/* Default */}
          {/* <p style={{width: "60em", fontSize: "15px", margin: "0px" }} dangerouslySetInnerHTML={{ __html: rightValue }}></p> */}

          {/* Modified */}
          <p style={{width: "30em", fontSize: "15px", margin: "0px" }} dangerouslySetInnerHTML={{ __html: rightValue }}></p>
        </StyledTableCell>
      
      </StyledOddTableRow >
    );
  }


  const getTopTable = () => {
    if(paramDetail){
      var accessMethods = Object.values(access_definition).filter(def => paramDetail.access_ids.includes(def.id)).map(acc => acc.label).join(", ")
      return (
        <div className='param-detail-top-table'>
        <Grid container spacing={0}>
          <Grid item xs={5} sm={12}>
            <Table aria-label="customized table" >
              <TableBody>
                {getRowTopTable("Name", paramDetail.name)}
                {getRowTopTable("Short name", paramDetail.shortname)}
                {getRowTopTable("Unit", units_definition && units_definition[paramDetail.unit_id] ? units_definition[paramDetail.unit_id].name : "")}
                {getRowTopTable("Description", paramDetail.description)}
                {/* {getRowTopTable("Documentation", "TO BE ADDED VIA API")} */}
                {getRowTopTable("Access methods", accessMethods == "" ? "" : accessMethods+" - see below for details")}
                {getRowTopTable("ID", paramDetail.id)}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </div>
    );
    }
  }

  const getShowMoreOrLess = () => {
    return showMoreOrLess ? "Show more +" : "Show less -";
  }

  const handleShowMoreClick = () => {
    setShowMoreOrLess(!showMoreOrLess);
  }

  const getAdditionalTopTable = () => {
    return (
      <div>
        <Table aria-label="customized table">
          <TableBody>
            {getRowTopTable("Insert date", paramDetail.insert_date)}
            {getRowTopTable("Update date", paramDetail.update_date)}
            {getRowTopTable("Contact", paramDetail.contact)}
          </TableBody>
        </Table>
      </div>
    );
  }

  const getAdditionalTopTableShowMoreText = () => {
    return (
      <div>
        <div className="param-detail-show-more-button">
          <Button onClick={handleShowMoreClick} style={{textTransform: "none"}}>{getShowMoreOrLess()}</Button>
        </div>
        {!showMoreOrLess && getAdditionalTopTable()}
      </div>
    )
  }

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const getRowId = (row) =>{
    return row.name+"_"+row.value+"_"+row.label
  }

  const getCellEncoding = (value) =>{
    return (
          <StyledTableCell align="left" >
              <p style={{ fontSize: "15px", margin: "0px", width: '140px' }}>{value !== "" ? value : "-"}</p>
          </StyledTableCell>
    )
  } 
  
  const getCellAccessMethods = (values) => {
    return (
      <StyledTableCell align="left" backgroundColor="#EFF2F6" colSpan={3}>
        <div className='stack-chip-label'>
          <span>Access Methods: </span>
          <Stack direction="row" ml={1}>
          {
            values.map(value => (
                <Chip
                  size="small"
                  label={value.label}
                />
            ))
          }
          </Stack>
        </div>
        
      </StyledTableCell>
    )
  }

  const getCellWarning= () => {
    return (
      <StyledTableCell align="left" backgroundColor="#EFF2F6" colSpan={3}>
        <div className='stack-chip-label'>
          <Stack direction="row" spacing={1}>
            <WarningAmberIcon/>
            <Typography>This rappresentation is not published</Typography>
          </Stack>
        </div>
        
      </StyledTableCell>
    )
  }

  const getTableDefaultEncoding = (defaultEncodings) => {
    return (
      defaultEncodings.map(encoding => (
        <div className="param-details-table-tab">
          <TableContainer>
            <Table className='tablesDefault' sx={{minWidth: 3000}} aria-label="customized table">
                <TableBody>
                {
                  encoding.published ?
                  ""
                  :
                  <StyledWarningTableRow>
                    {getCellWarning()}
                  </StyledWarningTableRow>
                }
                </TableBody>
                
                <TableBody>
                  {
                  encoding.keys.map(row => {if(encoding.published) {
                  
                    return(
                    <StyledFullToneTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }}} key={getRowId(row)}>
                      {getCellEncoding(row.name)}
                      {getCellEncoding(row.value)}
                      {getCellEncoding(row.label)}
                    </StyledFullToneTableRow >
                    )
                  } 
                  else
                  {
                    return(isAdmin &&
                    <StyledFullToneTableRowUnpublish sx={{ '&:last-child td, &:last-child th': { border: 0 }}} key={getRowId(row)}>
                      {getCellEncoding(row.name)}
                      {getCellEncoding(row.value)}
                      {getCellEncoding(row.label)}
                    </StyledFullToneTableRowUnpublish >
                    )
                  }
                })
                }
                </TableBody>
        
                <TableFooter>
                  {
                  encoding.published ?
                    <StyledFooterTableRow >
                      {getCellAccessMethods(Object.values(access_definition).filter(def => encoding.access_ids.includes(def.id)))}
                    </StyledFooterTableRow> 
                  :
                  (isAdmin  && 
                  <StyledFooterTableRowUnpublish>
                    {getCellAccessMethods(Object.values(access_definition).filter(def => encoding.access_ids.includes(def.id)))}
                  </StyledFooterTableRowUnpublish>
                  )
                  }
                </TableFooter>
              
            </Table>
            </TableContainer>
        </div>
      ))
    )
  } 

  const getTablesAccordion = (encodingKeys) => {
    return (
      <div className='param-details-table-accordion'>
        <TableContainer>
          <Table className='tablesAccordion' sx={{width: "300em"}} aria-label="customized table" >
            <TableBody>
              {
              encodingKeys.published ?
              ""
              :
              <StyledWarningTableRow>
                {getCellWarning()}
              </StyledWarningTableRow>
              }
            </TableBody>

            <TableBody>
                {
                encodingKeys.keys.map(row => {if(encodingKeys.published){
                return(
                <StyledFullToneTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }}} key={getRowId(row)}>
                  {getCellEncoding(row.name)}
                  {getCellEncoding(row.value)}
                  {getCellEncoding(row.label)}
                </StyledFullToneTableRow >
                )}

                else {
                return(
                <StyledFullToneTableRowUnpublish sx={{ '&:last-child td, &:last-child th': { border: 0 }}} key={getRowId(row)}>
                  {getCellEncoding(row.name)}
                  {getCellEncoding(row.value)}
                  {getCellEncoding(row.label)}
                </StyledFullToneTableRowUnpublish >
                  )}
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  const getTableOtherEncoding = (otherEncodings) => {
    return (
      <div>

      {otherEncodings.map(encoding =>
        <div>
          <Accordion sx={{boxShadow: 0}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{marginLeft: 0, borderBottom: 1}}
            >
              <Typography>
                Origin: <b>{origin[encoding.centre_id]? origin[encoding.centre_id].abbreviation: encoding.centre_id} </b>({origin[encoding.centre_id]? origin[encoding.centre_id].name: encoding.centre_id})
              </Typography>

            </AccordionSummary>

            <Table style={{tableLayout: 'fixed', marginTop:'10px', marginBottom:'5px'}} sx={{width: "300em"}} aria-label="customized table">
              <TableRow>
                <StyledTableCell><p><b>Key</b></p></StyledTableCell>
                <StyledTableCell><p><b>Value</b></p></StyledTableCell>
                <StyledTableCell><p><b>Label</b></p></StyledTableCell>
              </TableRow>
            </Table>

            <AccordionDetails
            >
              {getTablesAccordion(encoding)} 
            </AccordionDetails>
          
          </Accordion>
        </div>
        
      )}
      </div>

    );
  }

  const getPageTab = (defaultEncodings, otherEncodings) => {
    if((tab==='grib2' && grib2DefaultEncodings.length <= 0 && grib2OtherEncodings.length > 0) ||
    (tab==='grib1' && grib1DefaultEncodings.length <= 0 && grib1OtherEncodings.length > 0)){
      return(
        <div>
          {getTextBottomTablesTab(false)}
          {getTableOtherEncoding(otherEncodings)}
        </div>
      )
    }
    else{
      return(
        <div>
          <div className="param-details-origin-text">
            Origin: <img src={logo} /> <b>ECMWF</b> (European Center for Medium-range Forecast)
          </div>
          <TableContainer>
            <Table style={{tableLayout: 'fixed', marginTop:'10px', marginBottom:'5px'}}  aria-label="customized table">
              <TableBody>
                <StyledTableCell><p><b>Key</b></p></StyledTableCell>
                <StyledTableCell><p><b>Value</b></p></StyledTableCell>
                <StyledTableCell><p><b>Label</b></p></StyledTableCell>
              </TableBody>
            </Table>
          </TableContainer>

            {getTableDefaultEncoding(defaultEncodings)}
            {getTextBottomTablesTab(true)}
            {getTableOtherEncoding(otherEncodings)}
        </div>
        
      )
    }
  }

  const getPageTabNetCDF = () => {
    return (
      <>
        <Table style={{tableLayout: 'fixed', marginTop:'10px', marginBottom:'5px'}} sx={{width: "300em"}} aria-label="customized table">
          <TableRow>
            <StyledTableCell><p><b>Key</b></p></StyledTableCell>
            <StyledTableCell><p><b>Value</b></p></StyledTableCell>
            <StyledTableCell><p><b>Label</b></p></StyledTableCell>
          </TableRow>
        </Table>
    
        { netCDFEncodings.map(encoding =>{
              return(
                getTablesAccordion(encoding)
              )
            }
          )}
      </>
    );
  }
  // sx={{ width: '100%', borderBottom: 1, borderColor: 'divider', right: 0 }}
  const getTabsAndPageTabs = () => {
    
    return (
      <div className='param-details-tabs'>
        <Box>
          {/* <Paper> */}
            <Tabs
              value={tab}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
              TabIndicatorProps={{style: {display: 'none'}}}
              selected={true}
            >
          {/* <Box zIndex={1}>
          {grib2DefaultEncodings.length + grib2OtherEncodings.length > 0 && <Tab value="grib2" style={{textTransform: "none"}} label="GRIB edition 2"  />}
          </Box>

          <Box zIndex={1}>
          {grib1DefaultEncodings.length + grib1OtherEncodings.length > 0 && <Tab value="grib1" style={{textTransform: "none"}} label="GRIB edition 1" />}
          </Box>

          <Box zIndex={1}>
          {netCDFEncodings.length > 0 && <Tab value="netCDF" style={{textTransform: "none"}} label="NETCDF" />}
          </Box> */}

              {grib2DefaultEncodings.length + grib2OtherEncodings.length > 0 && <Tab className="tab" value="grib2" style={{textTransform: "none"}} label="GRIB edition 2"  />}
              {grib1DefaultEncodings.length + grib1OtherEncodings.length > 0 && <Tab className="tab" value="grib1" style={{textTransform: "none"}} label="GRIB edition 1" />}
              {netCDFEncodings.length > 0 && <Tab className="tab" value="netCDF" style={{textTransform: "none"}} label="NETCDF" />}
              <Tab className="tab" style={{width: '500px'}} disabled></Tab>
              <Tab className="tab" style={{width: '500px'}} disabled></Tab>
            </Tabs>
          {/* </Paper> */}
        </Box>

        {grib2DefaultEncodings.length + grib2OtherEncodings.length > 0 && tab === 'grib2' && getPageTab(grib2DefaultEncodings, grib2OtherEncodings)}
        {grib1DefaultEncodings.length + grib1OtherEncodings.length > 0 && tab === 'grib1' && getPageTab(grib1DefaultEncodings, grib1OtherEncodings)}
        {netCDFEncodings.length > 0 && tab === 'netCDF' && getPageTabNetCDF()}
      
      </div>
    );
  }

  const getTextBottomTablesTab = (bothEncodings) => {

    if(!bothEncodings){
      return(
        <div className="param-detail-text-betw-tables-list">
          <p>
            This parameter is only served by other forecasting centres.<br/>
            Please note that <b>you cannot retrieve data from ECMWF by using their IDs and ...</b>
          </p>
        </div>
      )
    }
    else{
      return(
        <div className="param-detail-text-betw-tables-list">
          <p>
            This parameter is also served by other forecasting centres.<br/>
            Please note that <b>you cannot retrieve data from ECMWF by using their IDs and ...</b>
          </p>
        </div>
      )
    }
  }

  const alertEncodings = () => {
    if(!storeLoading && paramDetail && grib1DefaultEncodings.length <=0 && grib2DefaultEncodings.length <=0){
      return(
        <div className='alert-encoding'>
          <Alert severity="warning" sx={{ backgroundColor: '#FDEDBB', color: '#635019', boxShadow: 'none', borderRadius: '6px' }}>This parameter is <b>not</b> available from ECMWF</Alert>
        </div>
      )
    }
    else if(!storeLoading && paramDetail && ((grib1DefaultEncodings.length > 0 && grib2DefaultEncodings.length <=0) || (grib1DefaultEncodings.length <=0 && grib2DefaultEncodings.length > 0))){
      return(
        <div className='alert-encoding'>
          <Alert severity="warning" sx={{ backgroundColor: '#FDEDBB', color: '#635019', boxShadow: 'none', borderRadius: '6px' }}>This parameter is partially available from ECMWF (see the representations below)</Alert>
        </div>
      )
    }
  }

  const getName = () => {
    if (paramDetail){
      return paramDetail.name + " - " + paramDetail.shortname
    }
    return ""
  } 

  const adminAction = (event) => {
    console.log(event.target.value)
    console.log(params.id)
    var selected = event.target.value
    switch(selected){
      case "edit":
        navigate("/parameter-edit/"+params.id);
        break
      case "clone":
        dispatch(setClone(true))
        navigate("/parameter-create");
        break
      case "delete":
        // handleOpen()
        setParameterSelected(params.id)
        handleOpenDelete()
        break
    }
  }

  const refresh = () => window.location.reload(true)

  const deleteDetailPage = (id) => {
    handleCloseDelete()
    deleteParameter(id)
    .then(
      (response) => {
        console.log(response)
        setMessageSnack({open: true, message: "Parameter succesfully deleted", severity: "success"})
        refresh()
      })

      .catch((error) => {
        setMessageSnack({open: true, message: "ERROR: "+error.response.data.detail, severity: "error"})
      })
      navigate('/')
    }
  
  return (
    // <Grid contaier spacing={0}>
    //   <Grid item xs={6} md={8} sm={12}>
    <>
    <Container disableGutters maxWidth="md" component="main" sx={{ p: 4}}>
      <div className="breadcrumb">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="always" color="primary" href="/">
            Parameter Database
          </Link>
          <Typography color="inherit">{getName()}</Typography>
        </Breadcrumbs>
      </div>

      <Grid container>
        {isLogged && isAdmin?  
          <Grid container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <h1>
                Parameter detail
              </h1>
            </Grid>
            <Grid item>
              <FormControl fullWidth style={{minWidth: 220}}>
                <InputLabel id="demo-simple-select-label">Administrator Actions</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Administrator Actions"
                  onChange={adminAction}
                  value=""
                >
                  <MenuItem value="edit">Edit</MenuItem>
                  <MenuItem value="clone">Clone</MenuItem>
                  <MenuItem value="delete">Delete</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          :
          <Grid container>
            <Grid item>
              <h1>
                Parameter detail
              </h1>
            </Grid>
          </Grid>
        }

        <Grid container>
          <Grid sx={1}></Grid>
          <Grid item sx={10} sm={12}>
            {alertEncodings()}
            {getTopTable()}
            {getAdditionalTopTableShowMoreText()}
            {getTabsAndPageTabs()}
          </Grid>
          <Grid sx={1}></Grid>
        </Grid>

        <Modal
              open={openModalDelete}
              onClose={handleCloseDelete}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Box sx={{ justifyContent: 'space-between',  display: 'flex' }}>
                  <Typography id="modal-modal-title" variant="h6" component="h2" sx={{p:2}}>
                    Please confirm
                  </Typography>
                  <IconButton sx={{ marginRight: "13px" }} style={{ width: "35px", height: "35px", marginTop: "15px"}} aria-label="delete" component="label">
                    <Clear onClick={handleCloseDelete}/>
                  </IconButton>
                </Box>
                <Divider></Divider>
                <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, p:2 }}>
                  What do you want to do?
                </Typography>
                <Divider></Divider>
                <Box sx={{ justifyContent: 'space-between',  display: 'flex', p:2 }}>
                  <Button
                    variant="outlined"
                    sx={{textTransform: "none", borderColor: "black", color: "black"}}
                    onClick={handleCloseDelete} 
                    >
                    Cancel
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{textTransform: "none"}} 
                    >
                    Unpublish this parameter
                  </Button>

                  {/* <Link href="/" underline="none"> */}
                    <Button
                      variant="contained"
                      color="error"
                      sx={{textTransform: "none"}}
                      type="submit"
                      onClick={() => deleteDetailPage(params.id)}
                      >
                      Delete this parameter
                    </Button>
                  {/* </Link> */}
                </Box>
              </Box>
            </Modal>
      </Grid>

      
    </Container>

    <>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleCloseSnack} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={handleCloseSnack} severity={severity} sx={{ width: '100%', boxShadow: 'none', borderRadius: '6px' }}>
        {message}
        </Alert>
      </Snackbar>
    </>
   </>
    
  );
};

export default ParameterDetail;





