import { SET_PARAMETERS } from "../constDefinition";

const initialState = {
  parameters: [],
  parametersCount: 0,
}

const parametersReducer = (state = initialState, action) => {
  switch(action.type)
  {
    case SET_PARAMETERS:
      return { 
        ...state, 
        parametersCount: action.values.count,
        parameters: Object.assign([], action.values.results)
      }

    // case 'ADD_ENTRY':
    //   return [...state, action.payload];

    // case 'REMOVE_ENTRY':
    //   state = state.filter(item => item.id !== action.payload)
    //   return state;

    // case 'UPDATE_ENTRY':
    //   state = state.map((todo) => todo.id === action.payload.id ? action.payload : todo);
    //   return state;
    
    // case 'ERASE_TABLE':
    //   return [];
    
    default: return state;
  }
};

export default parametersReducer;