import React, { isValidElement, useEffect, useState } from 'react';
import { FieldArray, Form, Formik, getIn, isEmptyArray } from "formik";
import * as Yup from "yup";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import { Button, Grid, OutlinedInput, TextField, Container, Paper, Box, Autocomplete, Divider, Switch, IconButton, stepButtonClasses } from '@mui/material';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDispatch, useSelector } from "react-redux";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddIcon from '@mui/icons-material/Add';
import { ContentCopy, Delete, Refresh } from "@mui/icons-material";
import { createParameter, createRepresentation, publishRepresentation } from "../services/ParameterService";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { dictIsEmpty } from '../shared/Utils';
import { setLoading } from '../actions';
import UnFoldIcon  from '../components/UnFoldIcon';
import { Navigate, useParams } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ButtonGroup from '@mui/material/ButtonGroup';
import Modal from '@mui/material/Modal';
import { Clear } from '@mui/icons-material';
import { createUnit } from "../services/UnitService";
import {useNavigate} from "react-router-dom";
import { unpublishRepresentation } from '../services/ParameterService';
import cloneParameter from '../components/ParametersTable'
import { setClone } from '../actions';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validationSchema = Yup.lazy((value) => {
  const shapes = {
    id: Yup.number().typeError('Id field must be a number')
      .required('Required'),
    shortName: Yup.string()
      .required('Required'),
    name: Yup.string()
      .required('Required'),
    description: Yup.string(),
    unit: Yup.string()
      .required('Required').typeError('Unit field must be a string'),
    documentation: Yup.array().of(
      Yup.object().shape({
        documentationLink: Yup.string()
      })
    ),
    representations: Yup.array().of(
      Yup.object().shape({
        format: Yup.string(),
        centre: Yup.string().when("format", {
          is: "NetCDF",
          then: Yup.string().nullable(true),
          otherwise: Yup.string()
        }),
        parameter_version: Yup.number(),
        expanded: Yup.boolean(),
        published: Yup.boolean(),
        keys: Yup.object().shape({
          key: Yup.string(),
          value: Yup.number().typeError('This field must be a number')
        })
      })
    ),

    contact: Yup.string(),
  }


  // const representations = value['representation']
  // console.log('**'+representations+'**')
  // representations.forEach((representation) => {
  //   const representationKeys = Object.keys(representation['keys'])
  //   representationKeys.forEach(((parameter) => {
  //     console.log(shapes)
  //     shapes['representations']['keys'][parameter] = Yup.number();
  //   }));
  // }) 

  return Yup.object().shape(shapes);
});

const debug = true;

//https://codesandbox.io/s/formik-fieldarray-materialui-f7rkz?file=/src/form.js

const ParameterCreate = () => {

  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState(null);
  const [newUnitValue, setNewUnitValue] = useState('');

  const navigate = useNavigate();

  // State of the modal on button 'Save'

  const [openModal, setOpenModal] = React.useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

  // Save buttons

  const [button, setButton] = useState(null);
  // Modale save semplice
   const setButton1 = () => setButton(1);

  //  Modale save and create
   const setButton2 = () =>  setButton(2);

  //  Modale save and clone
   const setButton3 = () =>  setButton(3);
   const setButton4 = () => setButton(4)

  // State of the menu and of the second modal 'save and create a new one'
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleOpenMenu = () => {
    let currentTarget = document.getElementById('currentTarget')
    setAnchorEl(currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [openSecondModal, setOpenSecondModal] = React.useState(false);
  const handleOpenSecondModal = () => setOpenSecondModal(true);
  const handleCloseSecondModal = () => setOpenSecondModal(false);

  const [openThirdModal, setOpenThirdModal] = React.useState();
  const handleOpenThirdModal = () => setOpenThirdModal(true);
  const handleCloseThirdModal = () => setOpenThirdModal(false);

  const [buttonMenu, setButtonMenu] = useState();

  const initialValues = {
      id: '',
      shortName: '',
      name: '',
      unit: '',
      description: '',
      documentation: [
        {
          id: Math.random(),
          documentationLink: ""
        }
      ],
      representations: [
      ],
      contact: ''
    }

  const params = useParams();

  const getPath = () => {
    return "/parameter-detail/"+params.id
  }

  const paramDetail = useSelector(state => state.paramDetail);
  
  const unitsOptions = useSelector(state => state.definition.units);
  const unitsOptionsByLabel = useSelector(state => state.definition.unitsByLabel)

  const formatsOptions = useSelector(state => state.definition.encoding)
  const formatsOptionsByLabel = useSelector(state => state.definition.encodingByLabel)

  const centresOptions = useSelector(state => state.definition.origins)
  const centresOptionsByLabel = useSelector(state => state.definition.originsByLabel)

  const [messageSnack, setMessageSnack] = React.useState({
    open: false,
    message: "",
    severity: "success"
  });
  const { open, message, severity } = messageSnack;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessageSnack({open: false, message: "", severity: "success"});
  };

  useEffect(() => {
    if(!dictIsEmpty(formatsOptionsByLabel) && !dictIsEmpty(centresOptions) && !dictIsEmpty(paramDetail) && paramDetail.clone){
      const p = paramDetail.parameterSelected

      var parameterRepresentations = []
        
        paramDetail.parameterSelectedGrib1DefaultEncodings.forEach( enc => {
          parameterRepresentations = addRepresentationKeys("GRIB1", enc, parameterRepresentations);
        })
        paramDetail.parameterSelectedGrib1OtherEncodings.forEach( enc => {
          parameterRepresentations = addRepresentationKeys("GRIB1", enc, parameterRepresentations);
        })

        paramDetail.parameterSelectedGrib2DefaultEncodings.forEach( enc => {
          parameterRepresentations = addRepresentationKeys("GRIB2", enc, parameterRepresentations);
        })
        paramDetail.parameterSelectedGrib2OtherEncodings.forEach( enc => {
          parameterRepresentations = addRepresentationKeys("GRIB2", enc, parameterRepresentations);
        })

        paramDetail.parameterSelectedNetCDFEncodings.forEach( enc => {
          parameterRepresentations = addRepresentationKeys("NetCDF", enc, parameterRepresentations);
        })

        const values = {
          id: p.id,
          shortName: p ? p.shortname : "",
          name: p ? p.name : "",
          unit: unitsOptions && p && p.unit_id && unitsOptions[p.unit_id] ? unitsOptions[p.unit_id].name : "",
          description: p ? p.description : "",
          documentation: [
            {
              id: Math.random(),
              documentationLink: ""
            }
          ],
          representations: parameterRepresentations,
          contact: ''
        }
        
        setFormValues(values)

        dispatch(setLoading(false))
    }
  
  }, [paramDetail, formatsOptionsByLabel, centresOptions])

  const addRepresentationKeys = (formatLabel, encoding, parameterRepresentations) => {

    let keysDefinition = formatsOptionsByLabel[formatLabel].keys;
    let keysReceived = Object.assign({}, ...keysDefinition.map((x) => ({ [x]: "" })));
    encoding.keys.forEach(key => {
      keysReceived[key["name"]] = key["value"];
    });

    parameterRepresentations.push({
      centre: formatLabel!= "NetCDF" ? centresOptions[encoding.centre_id].name : "",
      format: formatLabel,
      keys: keysReceived,
      published: encoding.published,
      parameter_version: encoding.parameter_version,
      id: encoding.id,
      expanded: true,
      new: false
    });

    return parameterRepresentations
  }

  const handleNewUnit = (e) => {
    setNewUnitValue(e.target.value)
  }

  const addNewUnit = () => {

    console.log(newUnitValue)
    let data = {
      name: newUnitValue
    }
    createUnit(data)
      .then(
        response => {
          console.log(response)
          setMessageSnack({open: true, message: "New unit succesfully created", severity: "success"})
        })
      .catch((error) => {
          console.log(error)
          setMessageSnack({open: true, message: "Error creating a new unit", severity: "error"})
        })
  }

  const addParameter = (parameter) => {
    //TODO: DOCUMENTATION???
    var data = {
      "id": parameter.id,
      "name": parameter.name,
      "shortname": parameter.shortName,
      "description": parameter.description,
      "unit_id": unitsOptionsByLabel[parameter.unit]['id'],
      "contact": parameter.contact
    }
    
    createParameter(data)
      .then(response => {
        //console.log(response)
         parameter.representations.forEach(representation => {
          let encodingKeys = []
          for (const [key, value] of Object.entries(representation.keys)) {
            if(value != ""){
              encodingKeys.push({"name":key, "value":value})
            }
          }
          let data = {
            centre_id: representation.format != "NetCDF" ? centresOptionsByLabel[representation.centre].id : "",
            version: representation.parameter_version,
            keys: encodingKeys,
            published: representation.published
          }
          var formatToSend = formatsOptionsByLabel[representation.format].id
          createRepresentation(parameter.id, formatToSend, data)
            .then(representation_response => {
              console.log(representation_response)
              if(representation.published){
                console.log('published')
                 publishRepresentation(parameter.id, representation_response.id, formatToSend)
                .then(
                  publish_response => {
                    console.log(publish_response)
                  }
                )
                .catch((publish_error) => {
                  console.log(publish_error)
                })
              }
              else{
                console.log('unpublished')
                unpublishRepresentation(parameter.id, representation_response.id, formatToSend)
                .then(
                  publish_response => {
                    console.log(publish_response)
                  }
                )
                .catch((publish_error) => {
                  console.log(publish_error)
                })
              }
            })
            .catch((representation_error) => {
              console.log(representation_error)
            })
        });
        //parameters.representations
        if(button === 1){
          setMessageSnack({open: true, message: "Parameter succesfully created", severity: "success"})
          setTimeout(navigate('/parameter-detail/' + parameter.id),1500)
          handleCloseModal()
          console.log(button)
        }
        else if(button === 2){
          setMessageSnack({open: true, message: "Parameter succesfully created", severity: "success"})
          setTimeout(navigate('/parameter-create/'),1500)
          handleCloseSecondModal()
          console.log(button)
        }
        else if(button === 3){
          setMessageSnack({open: true, message: "Parameter succesfully created", severity: "success"})
          setTimeout(navigate('/parameter-create/'),1500)
          handleCloseSecondModal()
          console.log(button)
        }
     
        
      })
      .catch((error) => {
        // Error
        // if (error.response) {
        //     // The request was made and the server responded with a status code
        //     // that falls out of the range of 2xx
        //     // console.log(error.response.data);
        //     // console.log(error.response.status);
        //     // console.log(error.response.headers);
        // } else if (error.request) {
        //     // The request was made but no response was received
        //     // `error.request` is an instance of XMLHttpRequest in the 
        //     // browser and an instance of
        //     // http.ClientRequest in node.js
        //     console.log(error.request);
        // } else {
        //     // Something happened in setting up the request that triggered an Error
        //     console.log('Error', error.message);
        // }
        
        setMessageSnack({open: true, message: "ERROR: "+error.response.data.detail, severity: "error"})
      })

  }

  const getData = () => {
    // console.log(formValues)
    return formValues || initialValues 
  }

  const getCentreStyle = (centreValue) => {
    if (centreValue == "NetCDF"){
      return { backgroundColor: 'gainsboro' };
    }
    else{
      return { "" : "" };
    }
  }

  const getCentrePlaceholder = (centreValue) => {
    if (centreValue == "NetCDF"){
      return "N/A";
    }
    else{
      return "";
    }
  }



  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 12,
  };



  const NewUnitModal = () => {

    const [openUnitModal, setOpenUnitModal] = React.useState(false);
    const handleOpenUnitModal = () => setOpenUnitModal(true);
    const handleCloseUnitModal = () => setOpenUnitModal(false);

    return(
      <React.Fragment>

        <Button
        onClick={handleOpenUnitModal}
        >
          Create a new unit +
        </Button>

        <Modal
          open={openUnitModal}
          onClose={handleCloseUnitModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* First box: please confirm and icon "X" */}
            <Box sx={{ justifyContent: 'space-between',  display: 'flex' }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" sx={{p:2}}>
                Add a new unit
              </Typography>
              <IconButton sx={{ marginRight: "13px" }} style={{ width: "35px", height: "35px", marginTop: "15px"}} aria-label="delete" component="label">
                <Clear onClick={handleCloseUnitModal}/>
              </IconButton>
            </Box>

            <Divider></Divider>

            {/* Second box: what do you want to do ? */}
            <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, p:2 }}>

              <Grid container>
                <Grid item xs={1}></Grid>

                <Grid item xs={10}>
                  <OutlinedInput
                    required
                    fullWidth
                    size="small"
                    id="newUnit"
                    name="newUnit"
                    placeholder='add new unit'
                  >
                  </OutlinedInput>
                </Grid>

                <Grid item xs={1}></Grid>
              </Grid>
              
            </Typography>

            <Divider></Divider>

            {/* Third box: buttons */}
            <Box sx={{ justifyContent: 'space-between',  display: 'flex', p:2 }}>
              <Button
                variant="outlined"
                sx={{textTransform: "none", borderColor: "black", color: "black"}}
                onClick={handleCloseUnitModal} 
              >
              Cancel
              </Button>

              <Button
                variant="contained"
                color="primary"
                sx={{textTransform: "none"}} 
                type="submit"
                onClick={() => {
                  // handleCloseUnitModal()
                  addNewUnit()
                  console.log('unita aggiunta')
                }}
              >
              Save this new unit
              </Button>

            </Box>
          </Box>
        </Modal>

      </React.Fragment>
    );
  }

  // const [form, setForm] = useState();

  return (
    <div>
      <Container disableGutters maxWidth="md" component="main" sx={{ p: 4}}>
        <div className='breadcrumb'>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="always" color="primary" href="/">
              Parameter Database
            </Link>
            <Typography color="inherit">New Parameter</Typography>
          </Breadcrumbs>
        </div>
        <h1>New parameter</h1>
        <h4><strong>Basic info</strong></h4>
          <Formik
            initialValues = {getData()}
            validationSchema={validationSchema}
            enableReinitialize={!dictIsEmpty(paramDetail) && paramDetail.clone} 
            onSubmit={values => {
              if(!dictIsEmpty(values)){
                console.log(values)
                console.log("onSubmit", JSON.stringify(values, null, 2));
                if(button === 1){
                  handleOpenModal()
                  console.log(1)
                }
                else if(button === 2){
                  handleOpenSecondModal()
                  console.log(2)
                }
                else if(button === 3){
                  handleOpenMenu()
                  console.log(3)
                }
            }
            else{
              <Link href="#" active></Link>
              console.log("onSubmit = non ci sono valori");
            }
              
            
            }}
            
          >
            {formikProps => (
                <Form noValidate autoComplete="off">
                  <Paper variant="outlined" sx={{ my: { xs: 1 }, p: { xs: 1, md: 2 } }}>  
                    <Grid container spacing={2} alignItems="flex-start" justifyContent="center">
                      
                      <Grid item xs={12} sm={3}>
                        {/* <label htmlFor="id">id</label> */}
                        <p>id: <span className="asteric-required">*</span></p>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <OutlinedInput
                          required
                          fullWidth
                          size="small"
                          id="id"
                          name="id"
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange}
                          value={formikProps.values.id}
                        >
                        </OutlinedInput>
                        {formikProps.touched.id && formikProps.errors.id ? (
                          <div style={{marginLeft:"5px", marginTop:"5px", color:"red"}}>{formikProps.errors.id}</div>
                        ) : null}  
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        {/* <label htmlFor="id">id</label> */}
                        <p>short name: <span className="asteric-required">*</span></p>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <OutlinedInput
                          required
                          fullWidth
                          size="small"
                          id="shortName"
                          name="shortName"
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange}
                          value={formikProps.values.shortName}
                        >
                        </OutlinedInput>
                        {formikProps.touched.shortName && formikProps.errors.shortName ? (
                          <div style={{marginLeft:"5px", marginTop:"5px", color:"red"}}>{formikProps.errors.shortName}</div>
                        ) : null}  
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        {/* <label htmlFor="id">id</label> */}
                        <p>name: <span className="asteric-required">*</span></p>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <OutlinedInput
                          required
                          fullWidth
                          size="small"
                          id="name"
                          name="name"
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange}
                          value={formikProps.values.name}
                        >
                        </OutlinedInput>
                        {formikProps.touched.name && formikProps.errors.name ? (
                              <div style={{marginLeft:"5px", marginTop:"5px", color:"red"}}>{formikProps.errors.name}</div>
                          ) : null}  
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        {/* <label htmlFor="id">id</label> */}
                        <p>unit:<span className="asteric-required">*</span></p>
                      </Grid>
                      
                      <Grid item xs={12} sm={9}>

                        <Autocomplete
                          disablePortal
                          fullWidth
                          size="small"
                          id="unit"
                          name="unit"
                          onBlur={formikProps.handleBlur}
                          options={
                            Object.keys(unitsOptions).map(function(key){
                              return unitsOptions[key].name;
                          })
                          }
                          renderInput={(params) => <TextField {...params} size="small" />}
                          value={formikProps.values.unit}
                          onChange={(event, value) => {
                            console.log("Value", value);
                            formikProps.setFieldValue("unit", value);
                          }}
                        />
                        {formikProps.touched.unit && formikProps.errors.unit ? (
                              <div style={{marginLeft:"5px", marginTop:"5px", color:"red"}}>{formikProps.errors.unit}</div>
                          ) : null} 
                      </Grid>

                      <Grid container>
                        <Grid item xs={12}>
                          <Box display="flex" justifyContent="flex-end">
                            {NewUnitModal()}
                          </Box>
                        </Grid>
                      </Grid>

                     
                      <Grid item xs={12} sm={3}>
                        {/* <label htmlFor="id">id</label> */}
                        <p>description:</p>
                      </Grid>

                      <Grid item xs={12} sm={9}>
                        <CKEditor
                          
                          editor={ClassicEditor}
                          // data="<p>Hello from CKEditor 5!</p>"
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            console.log("Editor is ready to use!", editor);
                            editor.editing.view.change((writer) => {
                            writer.setStyle(
                                "height",
                                "140px",
                                editor.editing.view.document.getRoot()
                            );
                            });
                          }}
                         
                          data={formikProps.values.description}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            formikProps.setFieldValue("description", data);
                          }}
                          onBlur={editor => {
                          }}
                          // onChange={formikProps.handleChange}
                          // onBlur={formikProps.handleBlur}
                          value={formikProps.values.description}
                        />
                      </Grid>

                      {/* <Grid item xs={12} sm={3}>
                        <label htmlFor="id">id</label>
                        <p>documentation link:</p>
                      </Grid> */}

                      {/* <Grid  item xs={12} sm={9}>
                          <FieldArray name="documentation">
                            {({ push, remove }) => (
                              <div style={{marginTop:"10px"}}>
                                {formikProps.values.documentation.map((p, index) => {
                                  const documentationLink = `documentation[${index}].documentationLink`;
                                  const touchedDocumentationLink = getIn(formikProps.touched, documentationLink);
                                  const errorDocumentationLink = getIn(formikProps.errors, documentationLink);

                                  return (
                                    <Grid container>
                                      <Grid item xs={12} sm={11}>
                                        <OutlinedInput
                                            style={{marginBottom: "15px"}}
                                            key={p.id}
                                            fullWidth
                                            size="small"
                                            id="documentationLink"
                                            name={documentationLink}
                                            error={Boolean(touchedDocumentationLink && errorDocumentationLink)}
                                            onChange={formikProps.handleChange}
                                            onBlur={formikProps.handleBlur}
                                        >
                                        </OutlinedInput>
                                      </Grid>
                                      <Grid item xs={12} sm={1}>
                                        <IconButton 
                                           style={{marginLeft:"10px"}}
                                           onClick={() => {
                                          console.log(index)
                                          remove(index)
                                        }}
                                        >
                                          <Delete sx={{ color: "#DC3545" }}/>
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Box display="flex" justifyContent="flex-end">
                                      <Button
                                      // style={{marginTop: "5px"}}
                                       onClick={() =>
                                          push({ id: Math.random(), documentationLink: ""})
                                        } variant="text">Add another link +</Button>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </div>
                            )}
                          </FieldArray>
                         
                        </Grid> */}

                        <Grid item xs={12} sm={3} style={{marginTop:'10px'}}>
                          {/* <label htmlFor="id">id</label> */}
                          <p>contact:</p>
                        </Grid> 
                        <Grid item xs={12} sm={9}>
                          <OutlinedInput
                            fullWidth
                            size="small"
                            id="contact"
                            name="contact"
                            style={{marginTop:'10px'}}
                            onBlur={formikProps.handleBlur}
                            onChange={formikProps.handleChange}
                            value={formikProps.values.contact}
                          >
                          </OutlinedInput> 
                          {formikProps.touched.contact && formikProps.errors.contact ? (
                              <div style={{marginLeft:"5px", marginTop:"5px", color:"red"}}>{formikProps.errors.contact}</div>
                          ) : null} 
                        </Grid>
                  </Grid>
                </Paper>

                <Divider sx={{marginTop: "30px", marginBottom: "30px"}} variant="middle"></Divider>
                <h4><strong>Representations</strong></h4>
                {formikProps.values.representations.length <= 0 && <p><i>There are no representations for this parameter yet</i></p>}

                <FieldArray name="representations">
                  
                    {({ push, remove }) => (
                      <div>
                          
                        {formikProps.values.representations.map((p, index) => {
                            const format = `representations[${index}].format`;
                            const touchedFormat = getIn(formikProps.touched, format);
                            const errorFormat = getIn(formikProps.errors, format);
                            const centre = `representations[${index}].centre`;
                            const touchedCentre = getIn(formikProps.touched, centre);
                            const errorCentre = getIn(formikProps.errors, centre);
                            const keys = `representations[${index}].keys`;
                            const expanded = `representations[${index}].expanded`
                            const expandedStatus = formikProps.values.representations[index].expanded;
                            const formatValue = formikProps.values.representations[index].format;
                            const centreValue = formikProps.values.representations[index].centre;
                            const firstKeysKey = Object.keys(p.keys)[0];
                            const firstKeysValue = p.keys[firstKeysKey];
                            const published = `representations[${index}].published`;
                            const publishedStatus = formikProps.values.representations[index].published;
                            const parameter_version = `representations[${index}].parameter_version`;

                            return (
                              <Paper variant="outlined" sx={{ my: { xs: 1 }, p: { xs: 1, md: 2 } }}>
                                <Box 
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    // p: 1,
                                    // m: 1,
                                  }}>
                                  
                                  <IconButton sx={{ marginRight: "13px" }} aria-label="delete" component="label"
                                  onClick={() =>{
                                    formikProps.setFieldValue(expanded, !expandedStatus)
                                  }
                                  }>
                                    <UnFoldIcon expanded={ expandedStatus }/>
                                  </IconButton>

                                  <IconButton sx={{ marginRight: "13px" }} aria-label="delete" component="label">
                                    <ContentCopy onClick={()=>{
                                      push({
                                        id: Math.random(),
                                        format: formatValue,
                                        centre: centreValue,
                                        keys: p.keys,
                                        expanded: true,
                                        published: publishedStatus,
                                        parameter_version: parameter_version,
                                        new: true
                                      })
                                    }} color="primary"/>
                                  </IconButton>
                                  
                                  <IconButton 
                                    sx={{ marginRight: "13px" }} 
                                    aria-label="delete" 
                                    component="label"
                                    onClick={() => {
                                      remove(index)
                                    }
                                  }>
                                    <Delete sx={{ color: "#DC3545" }}/>
                                  </IconButton>
                                </Box>

                                { expandedStatus?

                                <Grid container spacing={2} alignItems="flex-start" justifyContent="center">
                                  <Grid item xs={12} md={3}>
                                    <p>Format:</p>
                                    <Autocomplete
                                      disablePortal
                                      fullWidth
                                      size="small"
                                      error={Boolean(touchedFormat && errorFormat)}
                                      name={format}
                                      validationSchema={validationSchema}
                                      options={
                                        Object.keys(formatsOptions).map(function(key){
                                          return formatsOptions[key].label;
                                      })
                                      }
                                      renderInput={(params) => <TextField {...params}  size="small"/>}
                                      value={formikProps.values.representations[index].format}
                                      onChange={(event, value) => {
                                        // console.log("Value", value);
                                        if (value === null) {
                                          formikProps.setFieldValue(published, false)
                                        }else{
                                        var data = formatsOptionsByLabel[value].keys
                                        
                                        formikProps.setFieldValue(
                                          keys, 
                                          Object.assign({}, ...data.map((x) => ({[x]: ""})))
                                        );}
                                        formikProps.setFieldValue(format, value);
                                        if (value == "NetCDF"){
                                          formikProps.setFieldValue(centre, null);
                                        }
                                      }}
                                    />
                                    
                                  </Grid>

                                  <Grid item xs={12} md={9}>
                                    <p>Centre:</p>
                                    <Autocomplete
                                      disablePortal
                                      fullWidth
                                      size="small"
                                      error={Boolean(touchedCentre && errorCentre)}
                                      name={centre}
                                      disabled={formatValue == "NetCDF"}
                                      validationSchema={validationSchema}
                                      style={getCentreStyle(formatValue)}
                                      options={
                                        Object.keys(centresOptions).map(function(key){
                                          return centresOptions[key].name;
                                      })
                                      }
                                      renderInput={(params) => <TextField {...params}  size="small" label={getCentrePlaceholder(formatValue)}/>}
                                      value={formikProps.values.representations[index].centre}
                                      onChange={(event, value) => {
                                        // console.log("Value", value);
                                        formikProps.setFieldValue(centre, value);
                                      }}
                                    />

                                    <p>Parameter Version:</p>
                                    <OutlinedInput
                                      fullWidth
                                      size="small"
                                      name={parameter_version}
                                      onChange={(event) => {
                                        const value = event.target.value
                                        formikProps.setFieldValue(parameter_version, value);
                                      }}
                                      value={formikProps.values.representations[index].parameter_version}
                                    >
                                    </OutlinedInput> 
                                    
                                    {/* Input to controll for validation */}

                                    <Grid container sx={{marginTop: "16px"}}>
                                      {p.keys && Object.keys(p.keys).map(key => {
                                          //let value = p.keys[key];
                                          const repKeyValue = `representations[${index}].keys[${key}]`;
                                          // console.log('tipo di key: ' + typeof(key))
                                          // console.log(key)
                                          // console.log(repKeyValue)
                                          return(
                                            <>
                                              <Grid item xs={12} sm={6}>
                                                {/* <label htmlFor="id">id</label> */}
                                                <p>{key}: </p>
                                              </Grid>
                                              <Grid item xs={12} sm={6}>
                                                <OutlinedInput
                                                  fullWidth
                                                  size="small"
                                                  validationSchema={validationSchema}
                                                  // type="number" 
                                                  id={repKeyValue}
                                                  name={repKeyValue}
                                                  value={formikProps.values.representations[index].keys[key]}
                                                  onBlur={formikProps.handleBlur}
                                                  onChange={(event) => {
                                                    const value = event.target.value
                                                    formikProps.setFieldValue(repKeyValue, value);
                                                  }}
                                                >
                                                </OutlinedInput>
                                                
                                                {formikProps.touched.value && formikProps.errors.value ? (
                                                  <div style={{marginLeft:"5px", marginTop:"5px", marginBottom:"10px", color:"red"}}>{formikProps.errors.value}</div>
                                                ) : null}
                                                
                                              </Grid> 
                                            </>
                                          )
                                        })
                                        
                                      }
                                    </Grid>

                                    <Divider sx={{marginTop: "30px", marginBottom: "30px"}} variant="middle"></Divider>
                                      
                                    <Grid container>
                                      <Grid item xs={12} sm={3}>
                                        <p>published:</p>
                                      </Grid>
                                      <Grid item xs={12} sm={9}>
                                      {formatValue? 
                                            <Switch checked={publishedStatus} onChange={(e)=>
                                            {
                                              if (e.target.checked) {
                                                formikProps.setFieldValue(published, true)
                                              } else {
                                                formikProps.setFieldValue(published, false)
                                              }
                                            }
                                          }/>
                                          :
                                          <Switch checked={publishedStatus} disabled/>
                                        }
                                      </Grid>
                                    </Grid>

                                  </Grid>
                              </Grid>
                              : 
                              <Grid>
                                <Grid container spacing={2} item p={2} alignItems="start" justifyContent="start">
                                  <p>Format: {formatValue}; Centre: {centreValue}; {firstKeysKey}: {firstKeysValue}...
                                  [expand to read more]</p>
                                </Grid>
                                <Divider sx={{marginTop: "10px", marginBottom: "10px"}} variant="width"></Divider>
                                <Grid container spacing={2} item p={2} alignItems="start" justifyContent="start">
                                  <p>This representation is currently: <b>{publishedStatus? "published" : "not published"}</b></p>
                                </Grid>
                              </Grid>
                              }
                            </Paper>
                            );
                          })}
                        <Grid container>
                          <Grid item xs={12}>
                            <Box display="flex" justifyContent="flex-end">
                              <Button onClick={() =>{
                                push({ id: Math.random(), centre: "", format: "", keys: {}, parameter_version: 0, expanded:true, published: false, new: true})
                              }
                                  
                                } variant="text">Add a representation +</Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  
                </FieldArray>

                <Divider sx={{marginTop: "30px", marginBottom: "30px"}} variant="middle"></Divider>

                <Grid 
                  container 
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center">
                  <Grid item>
                    <Button
                      sx={{textTransform: "capitalize", marginLeft: "10px"}} 
                      variant="contained"
                      color="error">
                      Delete
                    </Button>
                  </Grid>
                  <Grid item>
                    <Grid 
                    container 
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center">

                      <Grid item>
                        <div>
                          <ButtonGroup>

                            <Button
                              sx={{textTransform: "capitalize", marginLeft: "10px", borderColor: "black", color: "black"}} 
                              variant="outlined"
                              type="submit"
                              onClick={setButton2}
                            >
                            Save and create a new one
                            </Button>

                            
                              <Button   
                                sx={{textTransform: "capitalize", padding: "1px", borderColor: "black", color: "black"}} 
                                id="currentTarget"
                                aria-controls={openMenu ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openMenu ? 'true' : undefined}
                                // type="submit"
                                onClick={handleOpenMenu}
                              >
                                <AddIcon/>
                              </Button>

                          </ButtonGroup>

                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleCloseMenu}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          >
                            

                            <MenuItem
                              onClick={
                                () => {
                                  handleOpenSecondModal()
                                  setButton2()
                                  return
                              }}
                            >
                                Create blank
                            </MenuItem>
                            
                            <MenuItem 
                            type='submit'
                              onClick={
                                () => {
                                handleOpenThirdModal()
                                setButton3()
                                return
                              }}
                            >
                                Clone the current
                            </MenuItem>
                          </Menu>

                          {/* Save and clone: Modal */}

                          <Modal
                            open={openThirdModal}
                            onClose={handleCloseThirdModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              {/* First box: please confirm and icon "X" */}
                              <Box sx={{ justifyContent: 'space-between',  display: 'flex' }}>
                                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{p:2}}>
                                  Please confirm
                                </Typography>
                                <IconButton sx={{ marginRight: "13px" }} style={{ width: "35px", height: "35px", marginTop: "15px"}} aria-label="delete" component="label">
                                  <Clear onClick={handleCloseThirdModal}/>
                                </IconButton>
                              </Box>

                              <Divider></Divider>

                              {/* Second box: what do you want to do ? */}
                              <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, p:2 }}>
                                <label>Are you sure you want to save this parameter ? </label><br/>
                                <label>All its rappresentation in a <em>published</em> state will be available to end-users</label>
                              </Typography>

                              <Divider></Divider>

                              {/* Third box: buttons */}
                              <Box sx={{ justifyContent: 'space-between',  display: 'flex', p:2 }}>
                                <Button
                                  variant="outlined"
                                  sx={{textTransform: "none", borderColor: "black", color: "black"}}
                                  onClick={handleCloseThirdModal} 
                                >
                                  Cancel
                                </Button>

                                <Button
                                  className="buttonPrimary"
                                  variant="contained"
                                  color="primary"
                                  sx={{textTransform: "none"}} 
                                  type="submit"
                                  onClick={() => {
                                    handleCloseThirdModal()
                                    addParameter(formikProps.values)
                                  }}
                                >
                                  Yes, save this parameter and clone 
                                </Button>
                              </Box>
                            </Box>
                          </Modal>

                          {/* Save and create: Modal */}

                          <Modal
                            open={openSecondModal}
                            onClose={handleCloseSecondModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              {/* First box: please confirm and icon "X" */}
                              <Box sx={{ justifyContent: 'space-between',  display: 'flex' }}>
                                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{p:2}}>
                                  Please confirm
                                </Typography>
                                <IconButton sx={{ marginRight: "13px" }} style={{ width: "35px", height: "35px", marginTop: "15px"}} aria-label="delete" component="label">
                                  <Clear onClick={handleCloseSecondModal}/>
                                </IconButton>
                              </Box>

                              <Divider></Divider>

                              {/* Second box: what do you want to do ? */}
                              <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, p:2 }}>
                                <label>Are you sure you want to save this parameter ? </label><br/>
                                <label>All its rappresentation in a <em>published</em> state will be available to end-users</label>
                              </Typography>

                              <Divider></Divider>

                              {/* Third box: buttons */}
                              <Box sx={{ justifyContent: 'space-between',  display: 'flex', p:2 }}>
                                <Button
                                  variant="outlined"
                                  sx={{textTransform: "none", borderColor: "black", color: "black"}}
                                  onClick={handleCloseSecondModal} 
                                >
                                  Cancel
                                </Button>

                                <Button
                                  className="buttonPrimary"
                                  variant="contained"
                                  color="primary"
                                  sx={{textTransform: "none"}} 
                                  type="submit"
                                  onClick={() => {
                                    setButton2()
                                    addParameter(formikProps.values)
                                  }}
                                >
                                  Yes, save this parameter and create a new one
                                </Button>
                              </Box>
                            </Box>
                          </Modal>
                        </div>
                      </Grid>

                        <Grid item>
                                              
                          <Button
                            className="buttonPrimary"
                            sx={{textTransform: "capitalize", marginLeft: "10px"}} 
                            type="submit"
                            variant="contained"
                            endIcon={<ChevronRightIcon />}
                            onClick={setButton1}
                          >
                            Save
                          </Button>

                          <Modal
                            open={openModal}
                            onClose={handleCloseModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              {/* First box: please confirm and icon "X" */}
                              <Box sx={{ justifyContent: 'space-between',  display: 'flex' }}>
                                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{p:2}}>
                                  Please confirm
                                </Typography>
                                <IconButton sx={{ marginRight: "13px" }} style={{ width: "35px", height: "35px", marginTop: "15px"}} aria-label="delete" component="label">
                                  <Clear onClick={handleCloseModal}/>
                                </IconButton>
                              </Box>

                              <Divider></Divider>

                              {/* Second box: what do you want to do ? */}
                              <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, p:2 }}>
                                <label>Are you sure you want to save this parameter ? </label><br/>
                                <label>All its rappresentation in a <em>published</em> state will be available to end-users</label>
                              </Typography>

                              <Divider></Divider>

                              {/* Third box: buttons */}
                              <Box sx={{ justifyContent: 'space-between',  display: 'flex', p:2 }}>
                                <Button
                                  variant="outlined"
                                  sx={{textTransform: "none", borderColor: "black", color: "black"}}
                                  onClick={handleCloseModal} 
                                >
                                Cancel
                                </Button>

                              {/* <Link href={'/parameter-detail/'+formikProps.values.id} underline='none'> */}
                                <Button
                                    className="buttonPrimary"
                                    variant="contained"
                                    color="primary"
                                    sx={{textTransform: "none"}} 
                                    onClick={() => {
                                      setButton1();
                                      addParameter(formikProps.values)
                                    }
                                    }
                                  >
                                  Yes, save this parameter
                                  </Button>
                                {/* </Link> */}
                              </Box>
                            </Box>
                          </Modal>

                        </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                


                {debug && (
                    <>
                      <pre style={{ textAlign: "left" }}>
                        <strong>Values</strong>
                        <br />
                        {JSON.stringify(formikProps.values, null, 2)}
                      </pre>
                      <pre style={{ textAlign: "left" }}>
                        <strong>Errors</strong>
                        <br />
                        {JSON.stringify(formikProps.errors, null, 2)}
                      </pre>
                    </>
                  )}

                      
              </Form>
              
            )}
          </Formik>
      </Container>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%', boxShadow: 'none', borderRadius: '6px' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ParameterCreate;
