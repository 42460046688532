import {
  CATEGORIES,
  DISCIPLINES,
  ENCODING,
  LOGGED,
  NOT_LOGGED,
  ORIGINS,
  ACCESS,
  UNITS,
  TABLES,
  SET_PARAMETERS,
  SET_N_ENTRIES,
  SET_PAGE_NUMBER,
  SET_ACCESS_METHOD,
  SET_FORMAT,
  SET_TABLE,
  SET_CATEGORY,
  SET_DISCIPLINE,
  SET_SEARCH,
  SET_PARAMETER_SELECTED,
  SET_PARAMETER_SELECTED_GRIB1_DEFAULT_ENCODINGS,
  SET_PARAMETER_SELECTED_GRIB2_DEFAULT_ENCODINGS,
  SET_PARAMETER_SELECTED_NETCDF_ENCODINGS,
  SET_ORIGIN,
  SET_LOADING,
  SET_PARAMETER_SELECTED_GRIB1_OTHER_ENCODINGS,
  SET_PARAMETER_SELECTED_GRIB2_OTHER_ENCODINGS,
  SET_PARAMETER_SELECTED_NETCDF_OTHER_ENCODINGS,
  SET_ADMIN,
  SET_ORDERING,
  SET_CLONE,
  REGEX
} from "../constDefinition"

////////////////////////////////////////////////////
// Authentication
////////////////////////////////////////////////////

export const logged = () => {
  return {
      type: LOGGED,
  }
}

export const notLogged = () => {
  return {
      type: NOT_LOGGED,
  }
}

export const setAdmin = (status) => {
  return {
      type: SET_ADMIN,
      value: status
  }
}


////////////////////////////////////////////////////
// Table
////////////////////////////////////////////////////

export const addEntry = (task) => {
  return {
    type: 'ADD_ENTRY',
    payload: task
  };
};

export const removeEntry = (task) => {
  return {
    type: 'REMOVE_ENTRY',
    payload: task
  };
};

export const updateEntry = (task) => {
  return {
    type: 'UPDATE_ENTRY',
    payload: task
  };
};

export const eraseTable = () => {
  return {
    type: 'ERASE_TABLE'
  };
}


////////////////////////////////////////////////////
// Filters
////////////////////////////////////////////////////

export const setOrderBy = (value) => {
  return {
    type: SET_ORDERING,
    value: value
  };
};

export const setPageNumber = (value) => {
  return {
    type: SET_PAGE_NUMBER,
    value: value
  };
};

export const setNEntriesPagination = (entries) => {
  return {
    type: SET_N_ENTRIES,
    value: entries
  };
};

export const setLoading = (value) => {
  return {
    type: SET_LOADING,
    value: value
  };
};

export const setAccessMethod = (value) => {
  return {
    type: SET_ACCESS_METHOD,
    value: value
  };
};

export const setFormat = (value) => {
  return {
    type: SET_FORMAT,
    value: value
  };
};

export const setTable = (value) => {
  return {
    type: SET_TABLE,
    value: value
  };
};

export const setCategory = (value) => {
  return {
    type: SET_CATEGORY,
    value: value
  };
};

export const setDiscipline = (value) => {
  return {
    type: SET_DISCIPLINE,
    value: value
  };
};

export const setOrigin = (value) => {
  return {
    type: SET_ORIGIN,
    value: value
  };
};

export const setSearch = (value) => {
  return {
    type: SET_SEARCH,
    value: value
  }
}

export const setRegex = (value) => {
  return {
    type: REGEX,
    value: value
  }
}


////////////////////////////////////////////////////
// Definition
////////////////////////////////////////////////////


export const setAccess = (values) => {
  return {
    type: ACCESS,
    values: values
  }
}

export const setUnits = (values) => {
  return {
    type: UNITS,
    values: values
  }
}

export const setTables = (values) => {
  return {
    type: TABLES,
    values: values
  }
}

export const setEncoding = (values) => {
  return {
    type: ENCODING,
    values: values
  }
}

export const setOrigins = (values) => {
  return {
    type: ORIGINS,
    values: values
  }
}
export const setDisciplines = (values) => {
  return {
    type: DISCIPLINES,
    values: values
  }
}
export const setCategories = (values) => {
  return {
    type: CATEGORIES,
    values: values
  }
}


////////////////////////////////////////////////////
// Table
////////////////////////////////////////////////////

export const setParameters = (values) => {
  return {
    type: SET_PARAMETERS,
    values: values
  }
}

////////////////////////////////////////////////////
// Detail
////////////////////////////////////////////////////
export const setClone = (value) => {
  return {
    type: SET_CLONE,
    value: value
  }
}

export const setParameterDetail = (value) => {
  return {
    type: SET_PARAMETER_SELECTED,
    value: value
  }
}

export const setParamDetailDefaultGrib1 = (value) => {
  return {
    type: SET_PARAMETER_SELECTED_GRIB1_DEFAULT_ENCODINGS,
    value: value
  }
}

export const setParamDetailOtherGrib1 = (value) => {
  return {
    type: SET_PARAMETER_SELECTED_GRIB1_OTHER_ENCODINGS,
    value: value
  }
}

export const setParamDetailDefaultGrib2 = (value) => {
  return {
    type: SET_PARAMETER_SELECTED_GRIB2_DEFAULT_ENCODINGS,
    value: value
  }
}

export const setParamDetailOtherGrib2 = (value) => {
  return {
    type: SET_PARAMETER_SELECTED_GRIB2_OTHER_ENCODINGS,
    value: value
  }
}

export const setParamDetailNetCDF = (value) => {
  return {
    type: SET_PARAMETER_SELECTED_NETCDF_ENCODINGS,
    value: value
  }
}

