import { LOGGED, NOT_LOGGED, SET_ADMIN } from "../constDefinition"

const initial_state = {
    logged: false,
    admin: false
}

const authReducer = (state = initial_state, action) => {
    switch(action.type) {
        case LOGGED:
            return {...state, logged:true}
        case NOT_LOGGED:
            return {...state, logged:false}
        case SET_ADMIN:
            return {...state, admin: action.value}
    }
    return state
}

export default authReducer;