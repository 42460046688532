import React from 'react'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';


const sortDirection = {
  asc: <ArrowUpwardIcon/>,
  desc: <ArrowDownwardIcon/>,
}

function ArrowIcon({ direction, color }) {
  if(color == 1){
    if(direction == 'asc'){
      return <ArrowUpwardIcon color="primary"/>
    }
    else{
      return <ArrowDownwardIcon color="primary"/>
    }
  }else{
    return sortDirection[direction]
  }
      
}

export default ArrowIcon