import { configureStore } from '@reduxjs/toolkit'

import parametersReducer from "./reducers/parametersReducer";
import paramDetailReducer from "./reducers/paramDetailReducer";
import definitionReducer from "./reducers/definitionReducer";
import filtersReducer from "./reducers/filtersReducer";
import authReducer from "./reducers/authReducer";

/*
const store = createStore(allReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
*/

var store = configureStore({
  reducer: {
    definition: definitionReducer,
    filters: filtersReducer,
    parameters: parametersReducer,
    paramDetail: paramDetailReducer,
    auth: authReducer
  },
})

export default store;